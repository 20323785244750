<template>
    <card
        class="flex flex-col justify-center items-center gap-2 p-6 text-red-400"
    >
        <icon
            name="mdi-table-remove"
            class="w-20 h-20"
        />

        <h3 class="m-0 p-0">
            {{
                __("Failed to load :resource!", {
                    resource: __(`${resource.label}`),
                })
            }}
        </h3>

        <v-button
            class="shrink-0"
            variant="outline"
            severity="alert"
            size="sm"
            @click="$emit('click')"
            :label="__('Reload')"
        />
    </card>
</template>

<script>
    export default {
        emits: ["click"],

        props: {
            resource: {
                type: Object,
                required: true,
            },
        },
    };
</script>
