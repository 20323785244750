<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <excerpt
                :content="field.value"
                :escape-html="field.asHtml"
                :should-show="field.shouldShow"
            />
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
