<template>
    <v-button
        v-for="action in limitedActions"
        :key="action.uriKey"
        :data-action-id="action.uriKey"
        @click="() => handleClick(action)"
        :label="action.name"
        :as="action.inertia ? 'link' : action.link ? 'a' : 'button'"
        :href="action.response?.data?.redirect"
        size="sm"
        padding="tight"
        :severity="
            action.link ? 'muted' : action.destructive ? 'alert' : 'primary'
        "
        :icon="action.icon"
        :disabled="action.authorizedToRun === false"
    />

    <slot />

    <action-dropdown
        v-if="extraActions.length > 0"
        :actions="extraActions"
        :resourceName="resourceName"
        :viaResource="viaResource"
        :viaResourceId="viaResourceId"
        :viaRelationship="viaRelationship"
        :relationshipType="relationshipType"
    >
        <template #trigger>
            <v-button
                severity="muted"
                icon="mdi-dots-horizontal"
                size="sm"
                padding="tight"
            />
        </template>
    </action-dropdown>
</template>

<script>
    import ActionHandler from "@nova/extendables/ActionHandler.vue";
    export default {
        extends: ActionHandler,

        emits: ["actionExecuted"],

        data: () => ({
            breakpoint: null,
        }),

        mounted() {
            this.breakpoint = this.calculateBreakpoint();

            window.addEventListener("resize", this.handleResize);
        },

        beforeUnmount() {
            window.removeEventListener("resize", this.handleResize);
        },

        methods: {
            handleClick(action) {
                if (action.link) {
                    return;
                }

                if (action.authorizedToRun !== false) {
                    this.handleActionClick(action.uriKey);
                }
            },

            handleResize() {
                this.breakpoint = this.calculateBreakpoint();

                console.log(this.breakpoint);
            },

            calculateBreakpoint() {
                const breakpoint = this.breakpoints.find(
                    (b) => window.innerWidth > b[1],
                );

                return breakpoint ? breakpoint[0] : "xs";
            },
        },

        computed: {
            limit() {
                return (
                    {
                        xs: 1,
                        sm: 3,
                        md: 4,
                        lg: 3,
                        xl: 4,
                    }[this.breakpoint] || 6
                );
            },

            limitedActions() {
                return this.actions.slice(0, this.limit);
            },

            extraActions() {
                return this.actions.slice(this.limit);
            },

            breakpoints() {
                if (!this.$pluginOptions) {
                    return [];
                }

                return Object.entries(this.$pluginOptions.theme.screens)
                    .map((e) => [e[0], parseInt(e[1])])
                    .sort((a, b) => b[1] - a[1]);
            },
        },
    };
</script>
