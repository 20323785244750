<template>
    <default-field
        :field="field"
        :error="error"
    >
        <template #field>
            <textarea
                v-bind="extraAttributes"
                class="form-control"
                @input="handleChange"
                :value="value"
                :id="currentField.uniqueKey"
                :dusk="field.attribute"
                :disabled="currentlyIsReadonly"
                :maxlength="field.enforceMaxlength ? field.maxlength : -1"
            />

            <character-counter
                v-if="field.maxlength"
                :count="value?.length || 0"
                :limit="parseInt(field.maxlength)"
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,
        computed: {
            defaultAttributes() {
                return {
                    rows: this.currentField.rows || 4,
                    class: this.errorClasses,
                    placeholder: this.field.placeholder,
                };
            },

            extraAttributes() {
                const attrs = this.currentField.extraAttributes;

                return {
                    ...this.defaultAttributes,
                    ...attrs,
                };
            },
        },
    };
</script>
