<template>
    <filter-container :filter="filter">
        <template #filter>
            <div class="flex items-center gap-2">
                <input
                    v-bind="startExtraAttributes"
                    class="form-control"
                    v-model="startValue"
                    :dusk="`${currentField.uniqueKey}-range-start`"
                />

                <span>to</span>

                <input
                    v-bind="endExtraAttributes"
                    class="form-control"
                    v-model="endValue"
                    :dusk="`${currentField.uniqueKey}-range-end`"
                />
            </div>
        </template>
    </filter-container>
</template>

<script>
    import InputField from "./InputField.vue";
    import toNumber from "lodash/toNumber";
    import filled from "@nova/utils/filled";

    export default {
        extends: InputField,

        data: () => ({
            startValue: null,
            endValue: null,
        }),

        watch: {
            startValue() {
                this.debouncedHandleChange();
            },
            endValue() {
                this.debouncedHandleChange();
            },
        },

        methods: {
            setCurrentFilterValue() {
                let [startValue, endValue] = this.filterValue || [null, null];

                this.startValue = filled(startValue)
                    ? toNumber(startValue)
                    : null;
                this.endValue = filled(endValue) ? toNumber(endValue) : null;
            },
        },

        computed: {
            emitValue() {
                let startValue = filled(this.startValue)
                    ? toNumber(this.startValue)
                    : null;

                let endValue = filled(this.endValue)
                    ? toNumber(this.endValue)
                    : null;

                if (
                    startValue !== null &&
                    this.currentField.min &&
                    this.currentField.min > startValue
                ) {
                    startValue = toNumber(this.currentField.min);
                }

                if (
                    endValue !== null &&
                    this.currentField.max &&
                    this.currentField.max < endValue
                ) {
                    endValue = toNumber(this.currentField.max);
                }

                return [startValue, endValue];
            },

            startExtraAttributes() {
                return {
                    ...this.extraAttributes,
                    placeholder: this.__("Min"),
                };
            },

            endExtraAttributes() {
                return {
                    ...this.extraAttributes,
                    placeholder: this.__("Max"),
                };
            },
        },
    };
</script>
