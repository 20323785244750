<template>
    <nav class="flex justify-between items-center">
        <!-- Previous Link -->
        <v-button
            :disabled="!hasPreviousPages || linksDisabled"
            variant="ghost"
            severity="muted"
            size="sm"
            rel="prev"
            @click.prevent="selectPreviousPage"
            dusk="previous"
            :label="__('Previous')"
        />

        <slot />

        <!-- Next Link -->
        <v-button
            :disabled="!hasMorePages || linksDisabled"
            variant="ghost"
            size="sm"
            rel="next"
            @click.prevent="selectNextPage"
            dusk="next"
            :label="__('Next')"
        />
    </nav>
</template>

<script>
    export default {
        emits: ["page"],

        props: {
            currentResourceCount: {
                type: Number,
                required: true,
            },
            allMatchingResourceCount: {
                type: Number,
                required: true,
            },
            resourceCountLabel: {
                type: [Number, String],
                required: true,
            },
            page: {
                type: Number,
                required: true,
            },
            pages: {
                type: Number,
                default: 0,
            },
            next: {
                type: Boolean,
                default: false,
            },
            previous: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({ linksDisabled: false }),

        mounted() {
            Nova.$on("resources-loaded", this.listenToResourcesLoaded);
        },

        beforeUnmount() {
            Nova.$off("resources-loaded", this.listenToResourcesLoaded);
        },

        methods: {
            selectPreviousPage() {
                this.selectPage(this.page - 1);
            },

            selectNextPage() {
                this.selectPage(this.page + 1);
            },

            selectPage(page) {
                this.linksDisabled = true;
                this.$emit("page", page);
            },

            listenToResourcesLoaded() {
                this.linksDisabled = false;
            },
        },

        computed: {
            hasPreviousPages: function () {
                return this.previous;
            },

            hasMorePages: function () {
                return this.next;
            },
        },
    };
</script>
