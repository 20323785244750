<template>
    <div :class="panelWidthClass">
        <component
            :key="`${field.attribute}:${resourceId}`"
            :is="`detail-${field.component}`"
            :resource-name="resourceName"
            :resource-id="resourceId"
            :resource="resource"
            :field="field"
            @actionExecuted="actionExecuted"
        />
    </div>
</template>

<script>
    import CollapsiblePanel from "@nova/extendables/CollapsiblePanel.vue";

    export default {
        extends: CollapsiblePanel,

        computed: {
            field() {
                return this.panel.fields[0];
            },
        },
    };
</script>
