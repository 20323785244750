<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template v-slot:field-value>
            <div class="flex flex-wrap gap-x-2 gap-y-1">
                <span
                    v-for="item in fieldValues"
                    v-text="item"
                    class="text-xs bg-blue-500 text-white px-3 py-1 rounded-full"
                />
            </div>
        </template>
    </panel-item>
</template>

<script>
    import forEach from "lodash/forEach";
    import indexOf from "lodash/indexOf";

    export default {
        props: ["index", "field"],

        computed: {
            fieldValues() {
                let selected = [];

                forEach(this.field.options, (option) => {
                    let value = option.value.toString();

                    if (
                        indexOf(this.field.value, value) >= 0 ||
                        indexOf(this.field.value?.toString(), value) >= 0
                    ) {
                        selected.push(option.label);
                    }
                });

                return selected;
            },
        },
    };
</script>
