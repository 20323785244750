<template>
    <div v-if="hasDropDownMenuItems">
        <popper>
            <template #trigger="{ popper }">
                <v-button
                    variant="outline"
                    severity="light"
                    padding="tight"
                    icon="mdi-trash-can"
                    trailing-icon="mdi-chevron-down"
                    :aria-label="__('Trash Dropdown')"
                    @click="popper.toggle()"
                />
            </template>
            <template #content>
                <dropdown-menu width="250">
                    <!-- Delete Menu -->
                    <dropdown-menu-item
                        v-if="shouldShowDeleteItem"
                        as="button"
                        dusk="delete-selected-button"
                        @click.prevent="confirmDeleteSelectedResources"
                    >
                        {{
                            __(
                                viaManyToMany
                                    ? "Detach Selected"
                                    : "Delete Selected",
                            )
                        }}
                        <badge
                            class="bg-blue-500 text-white ml-auto"
                            circle
                            :label="selectedResourcesWithoutTrashedCount"
                        />
                    </dropdown-menu-item>

                    <!-- Restore Resources -->
                    <dropdown-menu-item
                        v-if="shouldShowRestoreItem"
                        as="button"
                        dusk="restore-selected-button"
                        @click.prevent="confirmRestore"
                    >
                        {{ __("Restore Selected") }}
                        <badge
                            class="bg-blue-500 text-white ml-auto"
                            :label="selectedResourcesOnlyTrashedCount"
                            circle
                        />
                    </dropdown-menu-item>

                    <!-- Force Delete Resources -->
                    <dropdown-menu-item
                        v-if="shouldShowForceDeleteItem"
                        as="button"
                        dusk="force-delete-selected-button"
                        @click.prevent="confirmForceDeleteSelectedResources"
                    >
                        {{ __("Force Delete Selected") }}
                        <badge
                            class="bg-blue-500 text-white ml-auto"
                            :label="selectedResourcesCount"
                            circle
                        />
                    </dropdown-menu-item>
                </dropdown-menu>
            </template>
        </popper>

        <delete-resource-modal
            :mode="viaManyToMany ? 'detach' : 'delete'"
            :show="deleteSelectedModalOpen"
            @close="closeDeleteSelectedModal"
            @confirm="deleteSelectedResources"
            :count="selectedResourcesWithoutTrashedCount.length"
        />

        <delete-resource-modal
            :show="forceDeleteSelectedModalOpen"
            mode="force delete"
            @close="closeForceDeleteSelectedModal"
            @confirm="forceDeleteSelectedResources"
            :count="selectedResourcesCount"
        />

        <restore-resource-modal
            :show="restoreModalOpen"
            @close="closeRestoreModal"
            @confirm="restoreSelectedResources"
            :count="selectedResourcesOnlyTrashedCount.length"
        />
    </div>
</template>

<script>
    import find from "lodash/find";

    export default {
        emits: [
            "close",
            "deleteAllMatching",
            "deleteSelected",
            "forceDeleteAllMatching",
            "forceDeleteSelected",
            "restoreAllMatching",
            "restoreSelected",
        ],

        props: [
            "allMatchingResourceCount",
            "allMatchingSelected",
            "resources",
            "selectedResources",
            "show",
            "softDeletes",
        ],

        inject: [
            "authorizedToDeleteAnyResources",
            "authorizedToDeleteSelectedResources",
            "authorizedToForceDeleteAnyResources",
            "authorizedToForceDeleteSelectedResources",
            "authorizedToRestoreAnyResources",
            "authorizedToRestoreSelectedResources",
            "queryStringParams",
            "trashedParameter",
            "viaManyToMany",
        ],

        data: () => ({
            deleteSelectedModalOpen: false,
            forceDeleteSelectedModalOpen: false,
            restoreModalOpen: false,
        }),

        /**
         * Mount the component.
         */
        mounted() {
            document.addEventListener("keydown", this.handleEscape);

            Nova.$on("close-dropdowns", this.handleClosingDropdown);
        },

        /**
         * Prepare the component to be unmounted.
         */
        beforeUnmount() {
            document.removeEventListener("keydown", this.handleEscape);

            Nova.$off("close-dropdowns", this.handleClosingDropdown);
        },

        methods: {
            confirmDeleteSelectedResources() {
                this.deleteSelectedModalOpen = true;
            },

            confirmForceDeleteSelectedResources() {
                this.forceDeleteSelectedModalOpen = true;
            },

            confirmRestore() {
                this.restoreModalOpen = true;
            },

            closeDeleteSelectedModal() {
                this.deleteSelectedModalOpen = false;
            },

            closeForceDeleteSelectedModal() {
                this.forceDeleteSelectedModalOpen = false;
            },

            closeRestoreModal() {
                this.restoreModalOpen = false;
            },

            deleteSelectedResources() {
                this.$emit(
                    this.allMatchingSelected
                        ? "deleteAllMatching"
                        : "deleteSelected",
                );
            },

            forceDeleteSelectedResources() {
                this.$emit(
                    this.allMatchingSelected
                        ? "forceDeleteAllMatching"
                        : "forceDeleteSelected",
                );
            },

            restoreSelectedResources() {
                this.$emit(
                    this.allMatchingSelected
                        ? "restoreAllMatching"
                        : "restoreSelected",
                );
            },

            handleEscape(e) {
                if (this.show && e.keyCode == 27) {
                    this.close();
                }
            },

            close() {
                this.$emit("close");
            },

            handleClosingDropdown() {
                this.deleteSelectedModalOpen = false;
                this.forceDeleteSelectedModalOpen = false;
                this.restoreModalOpen = false;
            },
        },

        computed: {
            trashedOnlyMode() {
                return this.queryStringParams[this.trashedParameter] == "only";
            },

            hasDropDownMenuItems() {
                return (
                    this.shouldShowDeleteItem ||
                    this.shouldShowRestoreItem ||
                    this.shouldShowForceDeleteItem
                );
            },

            shouldShowDeleteItem() {
                // prettier-ignore
                return ! this.trashedOnlyMode
                    && this.selectedResourcesWithoutTrashedCount > 0
                    && (
                        this.authorizedToDeleteSelectedResources ||
                        this.allMatchingSelected
                    );
            },

            shouldShowRestoreItem() {
                // prettier-ignore
                return this.softDeletes
                    && ! this.viaManyToMany
                    && this.selectedResourcesOnlyTrashedCount > 0
                    && (
                        this.authorizedToRestoreSelectedResources ||
                        this.allMatchingSelected
                    );
            },

            shouldShowForceDeleteItem() {
                // prettier-ignore
                return this.softDeletes
                    && ! this.viaManyToMany
                    && (
                        this.authorizedToForceDeleteSelectedResources ||
                        this.allMatchingSelected
                    );
            },

            selectedResourcesCount() {
                return this.allMatchingSelected
                    ? this.allMatchingResourceCount
                    : this.selectedResources.length;
            },

            selectedResourcesWithoutTrashedCount() {
                return this.allMatchingSelected
                    ? this.allMatchingResourceCount
                    : this.selectedResources.filter((r) => !r.softDeleted)
                          .length;
            },

            selectedResourcesOnlyTrashedCount() {
                return this.allMatchingSelected
                    ? this.allMatchingResourceCount
                    : this.selectedResources.filter((r) => r.softDeleted)
                          .length;
            },

            softDeletedResourcesSelected() {
                return Boolean(
                    find(
                        this.selectedResources,
                        (resource) => resource.softDeleted,
                    ),
                );
            },
        },
    };
</script>
