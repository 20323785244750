<template>
    <default-field
        :field="currentField"
        :errors="errors"
    >
        <template #field>
            <select-dropdown
                :dusk="field.attribute"
                :options="options"
                :value="value"
                :placeholder="placeholder"
                @select="handleSelect"
                @clear="resetValue"
                :clearable="currentField.nullable"
                :disabled="currentlyIsReadonly"
                searchable
                as-html
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,

        methods: {
            handleSelect(option) {
                this.handleChange(option.value);
            },

            handleChange(value) {
                this.value = value;

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }

                this.updateValue(this.value);
            },
        },

        computed: {
            options() {
                return Object.entries(App.icons).map(([key, value]) => {
                    return {
                        value: key,
                        label: `
                            <div class="flex items-center gap-1">
                                <span>${value}</span>
                                <span>${key}</span>
                            </div>
                            `,
                    };
                });
            },

            fillValue() {
                return JSON.stringify(this.value);
            },

            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },
        },
    };
</script>
