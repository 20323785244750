<template>
    <default-field
        :field="currentField"
        :error="error"
    >
        <template #field>
            <date-time-input
                :id="currentField.uniqueKey"
                :dusk="field.attribute"
                :name="field.name"
                :placeholder="placeholder"
                :disabled="currentlyIsReadonly"
                :has-error="Boolean(error)"
                :options="currentField.pickerOptions"
                pick="datetime"
                :min="currentField.min"
                :max="currentField.max"
                v-model="value"
                @change="updateValue"
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,
        computed: {
            placeholder() {
                return (
                    this.currentField.placeholder ||
                    (!this.currentlyIsReadonly
                        ? this.__("Select a date...")
                        : "")
                );
            },
        },
    };
</script>
