<script>
    import Field from "./Field.vue";
    import isNil from "lodash/isNil";
    import { Errors } from "form-backend-validation";
    import { mapProps } from "@nova/utils";

    export default {
        extends: Field,
        emits: ["field-changed", "update:modelValue"],

        props: {
            ...mapProps(["mode"]),
            modelValue: {},
            errors: { default: () => new Errors() },
            formUniqueId: String,
        },

        inject: {
            index: { default: null },
            viaParent: { default: null },
        },

        data() {
            return {
                value: this.getDefaultValue(),
                errorClass: "form-control-error",
                silent: false,
            };
        },

        watch: {
            modelValue(newValue) {
                const casted = this.castValue(newValue);

                if (casted === this.value) {
                    return;
                }

                this.value = casted;
            },
        },

        created() {
            this.setInitialValue();
            this.updateModelValue();
        },

        mounted() {
            // Add a default fill method for the field
            this.field.fill = this.fill;

            // Register a global event for setting the field's value
            App.$on(
                this.fieldAttributeValueEventName,
                this.listenToValueChanges,
            );
        },

        beforeUnmount() {
            App.$off(
                this.fieldAttributeValueEventName,
                this.listenToValueChanges,
            );
        },

        methods: {
            getDefaultValue() {
                return null;
            },

            setInitialValue() {
                this.value = !(
                    this.field.value === undefined || this.field.value === null
                )
                    ? this.initialValue
                    : this.getDefaultValue();
            },

            resetValue() {
                this.updateValue(this.getDefaultValue());
            },

            /**
             * Fills a passed FormData object with the field's internal value.
             */
            fill(formData) {
                this.fillIfVisible(
                    formData,
                    this.field.attribute,
                    this.fillValue,
                );
            },

            fillIfVisible(formData, attribute, value) {
                if (this.isVisible) {
                    formData.append(attribute, value);
                }
            },

            handleChange(event) {
                this.updateValue(event?.target ? event.target.value : event);
            },

            updateValue(value) {
                this.value = value;

                if (!this.silent) {
                    this.$emit("field-changed", {
                        field: this.field,
                        value: this.value,
                    });
                }

                this.updateModelValue();
            },

            updateModelValue() {
                this.$emit("update:modelValue", this.emitValue);
            },

            castValue(value) {
                return value;
            },

            listenToValueChanges(value) {
                this.value = value;
            },

            emitFieldValue(attribute, value) {
                App.$emit(`${attribute}-value`, value);

                if (this.hasFormUniqueId === true) {
                    App.$emit(`${this.formUniqueId}-${attribute}-value`, value);
                }
            },

            emitFieldValueChange(attribute, value) {
                App.$emit(`${attribute}-change`, value);

                if (this.hasFormUniqueId === true) {
                    App.$emit(
                        `${this.formUniqueId}-${attribute}-change`,
                        value,
                    );
                }
            },

            getFieldAttributeValueEventName(attribute) {
                return this.hasFormUniqueId === true
                    ? `${this.formUniqueId}-${attribute}-value`
                    : `${attribute}-value`;
            },

            getFieldAttributeChangeEventName(attribute) {
                return this.hasFormUniqueId === true
                    ? `${this.formUniqueId}-${attribute}-change`
                    : `${attribute}-change`;
            },

            silently(callback) {
                const original = this.silent;

                this.silent = true;
                const result = callback();
                this.silent = original;

                return result;
            },
        },

        computed: {
            currentField() {
                return this.field;
            },

            fullWidthContent() {
                return this.currentField.fullWidth || this.field.fullWidth;
            },

            placeholder() {
                return this.currentField.placeholder;
            },

            isVisible() {
                return this.field.visible;
            },

            initialValue() {
                return this.castValue(this.field.value);
            },

            isReadonly() {
                return Boolean(
                    this.field.readonly ||
                        get(this.field, "extraAttributes.readonly"),
                );
            },

            isActionRequest() {
                return ["action-fullscreen", "action-modal"].includes(
                    this.mode,
                );
            },

            fillValue() {
                return this.value !== null ? String(this.value) : "";
            },

            emitValue() {
                return this.value;
            },

            errorClasses() {
                return this.hasError ? [this.errorClass] : [];
            },

            validationKey() {
                return this.nestedValidationKey || this.field.validationKey;
            },

            hasError() {
                return this.errors.has(this.validationKey);
            },

            firstError() {
                if (this.hasError) {
                    return this.errors.first(this.validationKey);
                }
            },

            error() {
                return this.firstError;
            },

            nestedAttribute() {
                if (this.viaParent) {
                    return `${this.viaParent}[${this.index}][${this.field.attribute}]`;
                }
            },

            nestedValidationKey() {
                if (this.viaParent) {
                    return `${this.viaParent}.${this.index}.fields.${this.field.attribute}`;
                }
            },

            hasFormUniqueId() {
                return !isNil(this.formUniqueId) && this.formUniqueId !== "";
            },

            fieldAttributeValueEventName() {
                return this.getFieldAttributeValueEventName(
                    this.field.attribute,
                );
            },

            fieldAttributeChangeEventName() {
                return this.getFieldAttributeChangeEventName(
                    this.field.attribute,
                );
            },
        },
    };
</script>
