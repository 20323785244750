import numbro from "numbro";
import numbroLanguages from "numbro/dist/languages.min";

function setupNumbro(locale) {
    numbro.setDefaults({
        thousandSeparated: true,
    });

    if (!locale) {
        return numbro;
    }

    locale = locale.replace("_", "-");

    Object.values(numbroLanguages).forEach((language) => {
        let name = language.languageTag;

        if (locale === name || locale === name.substr(0, 2)) {
            numbro.registerLanguage(language);
        }
    });

    numbro.setLanguage(locale);

    return numbro;
}

function formatTime(number, format = undefined) {
    const unit = (format.unit ||= "seconds");

    const per = {
        seconds: 1000,
        minutes: 1000 * 60,
        hours: 1000 * 60 * 60,
        days: 1000 * 60 * 60 * 24,
        weeks: 1000 * 60 * 60 * 24 * 7,
        years: 1000 * 60 * 60 * 24 * 7 * 52,
    };

    const ms = Math.round(number) * (per[unit] || 1);

    if (ms == 0) {
        return "0ms";
    }

    const years = Math.floor(ms / per.years);
    const weeks = Math.floor((ms % per.years) / per.weeks);
    const days = Math.floor((ms % per.weeks) / per.days);
    const hours = Math.floor((ms % per.days) / per.hours);
    const minutes = Math.floor((ms % per.hours) / per.minutes);
    const seconds = Math.floor((ms % per.minutes) / per.seconds);
    const remaining = Math.floor(ms % per.seconds);

    if (!format.average) {
        const result =
            (years > 0 ? `${years}y ` : "") +
            (weeks > 0 ? `${weeks}w ` : "") +
            (days > 0 ? `${days}d ` : "") +
            (hours > 0 ? `${hours}h ` : "") +
            (minutes > 0 ? `${minutes}m ` : "") +
            (seconds > 0 ? `${seconds}s ` : "") +
            (remaining > 0 ? `${remaining}ms ` : "");

        return result.trim();
    }

    const numberFormat = {
        ...format,
        output: "number",
    };

    if (years > 0) {
        return formatNumber(ms / per.years, numberFormat) + "y";
    } else if (weeks > 0) {
        return formatNumber(ms / per.weeks, numberFormat) + "w";
    } else if (days > 0) {
        return formatNumber(ms / per.days, numberFormat) + "d";
    } else if (hours > 0) {
        return formatNumber(ms / per.hours, numberFormat) + "h";
    } else if (minutes > 0) {
        return formatNumber(ms / per.minutes, numberFormat) + "m";
    } else if (seconds > 0) {
        return formatNumber(ms / per.seconds, numberFormat) + "s";
    }

    return formatNumber(ms, numberFormat) + "ms";
}

/**
 * @link https://github.com/BenjaminVanRyseghem/numbro/blob/master/src/validating.js
 *
 * @param {Object}           format
 * @param {string="number"}  format.output ("currency"|"percent"|"byte"|"time"|"ordinal"|"number")
 * @param {string="decimal"} format.base ("decimal"|"binary"|"general")
 * @param {string=}          format.prefix (e.g. "$")
 * @param {string=}          format.postfix (e.g. "%")
 * @param {string=}          format.forceAverage ("thousand"|"million"|"billion"|"trillion")
 * @param {boolean=false}    format.average
 * @param {boolean=false}    format.lowPrecision
 * @param {string="prefix"}  format.currencyPosition ("prefix"|"infix"|"postfix")
 * @param {string=}          format.currencySymbol (e.g. "$")
 * @param {Number=}          format.totalLength
 * @param {Number=}          format.mantissa
 * @param {boolean=false}    format.optionalMantissa
 * @param {boolean=false}    format.trimMantissa
 * @param {function=}        format.roundingFunction
 * @param {boolean=true}     format.thousandSeparated
 * @param {boolean=false}    format.spaceSeparated
 * @param {string=}          format.negative ("sign"|"parenthesis")
 * @param {boolean=false}    format.forceSign
 * @param {string=}          format.unit ("seconds"|"minutes"|"hours"|"days"|"weeks"|"years")
 */
function formatNumber(number, format = undefined) {
    if (typeof number === "string") {
        number = parseFloat(number);
    }

    if (!number || isNaN(number)) {
        return 0;
    }

    if (format?.output === "time") {
        return formatTime(number, format);
    }

    if (format && !format.output) {
        format.output = "number";
    }

    if (format?.unit) {
        delete format["unit"];
    }

    const lang =
        document.querySelector('meta[name="locale"]')?.content ||
        document.querySelector("html").getAttribute("lang") ||
        "en";

    const numbro = setupNumbro(lang);

    const num = numbro(number);

    if (format !== undefined && format !== null) {
        return num.format(format);
    }

    return num.format();
}

export default formatNumber;
