<template>
    <default-field
        :field="currentField"
        :error="error"
    >
        <template #field>
            <div class="flex items-center">
                <!-- @todo: Search Input -->
                <select-control
                    class="w-full"
                    :has-error="hasError"
                    :dusk="`${field.resourceName}-select`"
                    :disabled="currentlyIsReadonly"
                    :options="availableResources"
                    v-model:selected="selectedResourceId"
                    @change="selectResourceFromSelectControl"
                    label="display"
                >
                    <option
                        value=""
                        selected
                        :disabled="!currentField.nullable"
                    >
                        {{ placeholder }}
                    </option>
                </select-control>
                <!-- @todo: Create Relation Button -->
            </div>

            <!-- @todo: Create Relation Modal -->
            <!-- @todo: Trashed Checkbox -->
        </template>
    </default-field>
</template>

<script>
    import RelationField from "@nova/extendables/RelationField.vue";
    import filled from "@nova/utils/filled";
    import find from "lodash/find";
    import findIndex from "lodash/findIndex";
    import isNil from "lodash/isNil";

    export default {
        extends: RelationField,

        props: {
            resourceId: {},
        },

        data: () => ({
            initializingWithExistingResource: false,
            createdViaRelationModal: false,
            softDeletes: false,
            relationModalOpen: false,
        }),

        mounted() {
            this.initializeComponent();
        },

        methods: {
            initializeComponent() {
                this.withTrashed = false;

                this.selectedResourceId = this.currentField.value;

                if (this.editingExistingResource) {
                    // If a user is editing an existing resource with this relation
                    // we'll have a belongsToId on the field, and we should prefill
                    // that resource in this field

                    this.initializingWithExistingResource = true;
                    this.selectedResourceId = this.currentField.belongsToId;
                } else if (this.viaRelatedResource) {
                    // If the user is creating this resource via a related resource's index
                    // page we'll have a viaResource and viaResourceId in the params and
                    // should prefill the resource in this field with that information

                    this.initializingWithExistingResource = true;
                    this.selectedResourceId = this.viaResourceId;
                }

                if (this.shouldSelectInitialResource) {
                    if (this.useSearchInput) {
                        // If we should select the initial resource and the field is
                        // searchable, we won't load all the resources but we will select
                        // the initial option.

                        this.getAvailableResources().then(() =>
                            this.selectInitialResource(),
                        );
                    } else {
                        // If we should select the initial resource but the field is not
                        // searchable we should load all of the available resources into the
                        // field first and select the initial option.

                        this.initializingWithExistingResource = false;

                        this.getAvailableResources().then(() =>
                            this.selectInitialResource(),
                        );
                    }
                } else if (!this.isSearchable) {
                    // If we don't need to select an initial resource because the user
                    // came to create a resource directly and there's no parent resource,
                    // and the field is searchable we'll just load all of the resources.

                    this.getAvailableResources();
                }

                this.determineIfSoftDeletes();

                this.field.fill = this.fill;
            },

            selectResourceFromSelectControl(value) {
                this.selectedResourceId = value;
                this.selectInitialResource();

                if (this.field) {
                    this.emitFieldValueChange(
                        this.fieldAttribute,
                        this.selectedResourceId,
                    );
                }
            },

            fill(formData) {
                this.fillIfVisible(
                    formData,
                    this.field.attribute,
                    this.selectedResource ? this.selectedResource.value : "",
                );

                this.fillIfVisible(
                    formData,
                    `${this.field.attribute}_trashed`,
                    this.withTrashed,
                );
            },

            getAvailableResources() {
                Nova.$progress.start();

                return this.fetchAvailableResources(
                    this.resourceName,
                    this.field.attribute,
                    {
                        params: this.queryParams,
                    },
                )
                    .then(
                        ({ data: { resources, softDeletes, withTrashed } }) => {
                            Nova.$progress.done();

                            if (
                                this.initializingWithExistingResource ||
                                !this.isSearchable
                            ) {
                                this.withTrashed = withTrashed;
                            }

                            if (this.viaRelatedResource) {
                                let selectedResource = find(resources, (r) =>
                                    this.isSelectedResourceId(r.value),
                                );

                                if (
                                    isNil(selectedResource) &&
                                    !this.shouldIgnoreViaRelatedResource
                                ) {
                                    return Nova.visit("/404");
                                }
                            }

                            // Turn off initializing the existing resource after the first time
                            if (this.useSearchInput) {
                                this.initializingWithExistingResource = false;
                            }
                            this.availableResources = resources;
                            this.softDeletes = softDeletes;
                        },
                    )
                    .catch((e) => {
                        Nova.$progress.done();
                    });
            },

            fetchAvailableResources(resourceName, fieldAttribute, options) {
                return Nova.request().get(
                    `/nova-api/${resourceName}/associatable/${fieldAttribute}`,
                    options,
                );
            },

            determineIfSoftDeletes() {
                const resourceName = this.field.resourceName;

                return Nova.request()
                    .get(`/nova-api/${resourceName}/soft-deletes`)
                    .then((response) => {
                        this.softDeletes = response.data.softDeletes;
                    });
            },

            selectInitialResource() {
                this.selectedResource = find(this.availableResources, (r) =>
                    this.isSelectedResourceId(r.value),
                );
            },

            toggleWithTrashed() {
                let currentlySelectedResource;
                let currentlySelectedResourceId;

                if (filled(this.selectedResource)) {
                    currentlySelectedResource = this.selectedResource;
                    currentlySelectedResourceId = this.selectedResource.value;
                }

                this.withTrashed = !this.withTrashed;

                this.selectedResource = null;
                this.selectedResourceId = null;

                if (!this.useSearchInput) {
                    this.getAvailableResources().then(() => {
                        let index = findIndex(this.availableResources, (r) => {
                            return r.value === currentlySelectedResourceId;
                        });

                        if (index > -1) {
                            this.selectedResource =
                                this.availableResources[index];
                            this.selectedResourceId =
                                currentlySelectedResourceId;
                        } else {
                            // We didn't find the resource anymore, so let's remove the selection...
                            this.selectedResource = null;
                            this.selectedResourceId = null;
                        }
                    });
                }
            },

            openRelationModal() {
                Nova.$emit("create-relation-modal-opened");
                this.relationModalOpen = true;
            },

            closeRelationModal() {
                this.relationModalOpen = false;
                Nova.$emit("create-relation-modal-closed");
            },

            handleSetResource({ id }) {
                this.closeRelationModal();
                this.selectedResourceId = id;
                this.initializingWithExistingResource = true;
                this.createdViaRelationModal = true;
                this.getAvailableResources().then(() => {
                    this.selectInitialResource();

                    this.emitFieldValueChange(
                        this.field.attribute,
                        this.selectedResourceId,
                    );
                });
            },

            performResourceSearch(search) {
                if (this.useSearchInput) {
                    this.performSearch(search);
                } else {
                    this.search = search;
                }
            },

            clearResourceSelection() {
                this.clearSelection();

                if (this.viaRelatedResource && !this.createdViaRelationModal) {
                    this.updateQueryString({
                        viaResource: null,
                        viaResourceId: null,
                        viaRelationship: null,
                        relationshipType: null,
                    }).then(() => {
                        Nova.$router.reload({
                            onSuccess: () => {
                                this.initializingWithExistingResource = false;
                                this.initializeComponent();
                            },
                        });
                    });
                } else {
                    if (this.createdViaRelationModal) {
                        this.createdViaRelationModal = false;
                        this.initializingWithExistingResource = false;
                    }

                    this.getAvailableResources();
                }
            },

            onSyncedField() {
                if (this.viaRelatedResource) {
                    return;
                }

                this.initializeComponent();

                if (
                    isNil(this.syncedField.value) &&
                    isNil(this.selectedResourceId)
                ) {
                    this.selectInitialResource();
                }
            },

            emitOnSyncedFieldValueChange() {
                if (this.viaRelatedResource) {
                    return;
                }

                this.emitFieldValueChange(
                    this.fieldAttribute,
                    this.selectedResourceId,
                );
            },

            syncedFieldValueHasNotChanged() {
                return this.isSelectedResourceId(this.currentField.value);
            },

            isSelectedResourceId(value) {
                return (
                    !isNil(value) &&
                    value?.toString() === this.selectedResourceId?.toString()
                );
            },
        },

        computed: {
            editingExistingResource() {
                return filled(this.field.belongsToId);
            },

            viaRelatedResource() {
                return Boolean(
                    this.viaResource === this.field.resourceName &&
                        this.field.reverse &&
                        this.viaResourceId,
                );
            },

            shouldSelectInitialResource() {
                return Boolean(
                    this.editingExistingResource ||
                        this.viaRelatedResource ||
                        this.currentField.value,
                );
            },

            isSearchable() {
                return Boolean(this.currentField.searchable);
            },

            queryParams() {
                return {
                    current: this.selectedResourceId,
                    first: this.shouldLoadFirstResource,
                    search: this.search,
                    withTrashed: this.withTrashed,
                    resourceId: this.resourceId,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                    component: this.field.dependentComponentKey,
                    // dependsOn: this.encodedDependentFieldValues,
                    editing: true,
                    editMode:
                        isNil(this.resourceId) || this.resourceId === ""
                            ? "create"
                            : "update",
                };
            },

            shouldLoadFirstResource() {
                return (
                    (this.initializingWithExistingResource &&
                        !this.shouldIgnoreViaRelatedResource) ||
                    Boolean(this.currentlyIsReadonly && this.selectedResourceId)
                );
            },

            shouldShowTrashed() {
                return (
                    this.softDeletes &&
                    !this.viaRelatedResource &&
                    !this.currentlyIsReadonly &&
                    this.currentField.displaysWithTrashed
                );
            },

            authorizedToCreate() {
                return Nova.resourceInformation(this.field.resourceName)
                    .authorizedToCreate;
            },

            canShowNewRelationModal() {
                return (
                    this.currentField.showCreateRelationButton &&
                    !this.shownViaNewRelationModal &&
                    !this.viaRelatedResource &&
                    !this.currentlyIsReadonly &&
                    this.authorizedToCreate
                );
            },

            placeholder() {
                return this.currentField.placeholder || this.__("—");
            },

            filteredResources() {
                if (!this.isSearchable) {
                    return this.availableResources.filter((option) => {
                        return (
                            option.display
                                .toLowerCase()
                                .indexOf(this.search.toLowerCase()) > -1 ||
                            new String(option.value).indexOf(this.search) > -1
                        );
                    });
                }

                return this.availableResources;
            },

            shouldIgnoreViaRelatedResource() {
                return this.viaRelatedResource && filled(this.search);
            },

            useSearchInput() {
                return this.isSearchable || this.viaRelatedResource;
            },
        },
    };
</script>
