<template>
    <resource-index
        :field="field"
        :resource-name="field.resourceName"
        :via-resource="resourceName"
        :via-resource-id="resourceId"
        :via-relationship="field.hasManyRelationship"
        :relationship-type="'hasMany'"
        @actionExecuted="actionExecuted"
        :load-cards="false"
        :initialPerPage="field.perPage || 5"
        :should-override-meta="false"
    />
</template>

<script>
    export default {
        emits: ["actionExecuted"],

        props: ["resourceId", "field", "resourceName", "resource"],

        methods: {
            actionExecuted() {
                this.$emit("actionExecuted");
            },
        },
    };
</script>
