<template>
    <input
        :dusk="field.attribute"
        type="hidden"
        :value="value"
    />
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,
    };
</script>
