<template>
    <div class="border rounded shadow-inner relative">
        <copy-button
            v-if="copyable"
            @click.prevent.stop="copy"
            class="absolute top-0 right-0 z-10 mt-2"
        />
        <codemirror
            v-model="value"
            :autofocus="false"
            :indent-with-tab="false"
            :tab-size="4"
            :extensions="extensions"
            @change="handleChange"
            :disabled="disabled"
        />
    </div>
</template>

<style>
    .cm-editor {
        border-radius: 0.25rem;
    }

    .cm-gutters {
        border-radius: 0.25rem;
    }
</style>

<script>
    import { useClipboard } from "@vueuse/core";
    import { Codemirror } from "vue-codemirror";
    import { html } from "@codemirror/lang-html";
    import { json } from "@codemirror/lang-json";
    import { dracula } from "thememirror";

    export default {
        components: {
            Codemirror,
        },
        emits: ["update:modelValue", "change"],
        props: {
            language: {
                type: String,
                required: true,
                validator(value) {
                    return ["json", "html"].includes(value);
                },
            },
            theme: {
                type: String,
                default: "dracula",
                validator(value) {
                    return ["dracula"].includes(value);
                },
            },
            copyable: {
                type: Boolean,
                default: false,
            },
            modelValue: {},
            disabled: Boolean,
        },
        data() {
            return {
                value: this.modelValue,
                emitting: false,
            };
        },
        watch: {
            modelValue(newValue) {
                if (this.emitting) {
                    return;
                }

                this.value = newValue;
            },
        },
        methods: {
            handleChange(value) {
                this.emitting = true;
                this.$emit("update:modelValue", value);
                this.$emit("change", value);
                this.emitting = false;
            },
            copy() {
                useClipboard({
                    source: this.value,
                    legacy: true,
                }).copy(this.value);
            },
        },
        computed: {
            extensions() {
                return [this.languageExtension, this.themeExtension].filter(
                    (v) => v,
                );
            },
            languageExtension() {
                switch (this.language?.toLowerCase()) {
                    case "html":
                        return html();
                    case "json":
                        return json();
                    default:
                        return null;
                }
            },
            themeExtension() {
                switch (this.theme?.toLowerCase()) {
                    case "dracula":
                        return dracula;
                    default:
                        return null;
                }
            },
        },
    };
</script>
