<template>
    <default-field
        :field="field"
        :error="error"
    >
        <template #field>
            <!-- Search Input -->
            <select-dropdown
                v-if="!currentlyIsReadonly && isSearchable"
                :dusk="`${field.attribute}-search-input`"
                @clear="clearSelection"
                @select="selectOption"
                :has-error="hasError"
                :value="value"
                :options="field.options"
                :clearable="currentField.nullable"
                :disabled="currentlyIsReadonly"
                searchable
            />

            <!-- Select Input Field -->
            <select-control
                v-else
                :id="field.attribute"
                :dusk="field.attribute"
                v-model:selected="value"
                @change="handleChange"
                class="w-full"
                :has-error="hasError"
                :options="field.options"
                :disabled="currentlyIsReadonly"
            >
                <option
                    value=""
                    selected
                    :disabled="!field.nullable"
                >
                    {{ placeholder }}
                </option>
            </select-control>
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";
    import filled from "@nova/utils/filled";
    import find from "lodash/find";
    import first from "lodash/first";
    import isNil from "lodash/isNil";

    export default {
        extends: DependentFormField,

        data: () => ({
            search: "",
            selectedOption: null,
        }),

        created() {
            if (filled(this.field.value)) {
                const selectedOption = find(
                    this.field.options,
                    (v) => v.value == this.field.value,
                );

                this.$nextTick(() => {
                    this.silently(() => {
                        this.selectOption(selectedOption);
                    });
                });
            }
        },

        methods: {
            clearSelection() {
                this.selectedOption = null;
                this.value = this.getDefaultValue();

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }
            },

            selectOption(option) {
                if (isNil(option)) {
                    this.clearSelection();
                    return;
                }

                this.selectedOption = option;
                this.value = option.value;

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }

                this.updateValue(this.value);
            },

            handleChange(value) {
                let selectedOption = find(
                    this.currentField.options,
                    (v) => v.value == value,
                );

                this.selectOption(selectedOption);
            },

            onSyncedField() {
                let currentSelectedOption = null;
                let hasValue = false;

                if (this.selectedOption) {
                    hasValue = true;
                    currentSelectedOption = find(
                        this.currentField.options,
                        (v) => v.value === this.selectedOption.value,
                    );
                }

                let selectedOption = find(
                    this.currentField.options,
                    (v) => v.value == this.currentField.value,
                );

                if (isNil(currentSelectedOption)) {
                    this.clearSelection();

                    if (this.currentField.value) {
                        this.selectOption(selectedOption);
                    } else if (hasValue && !this.currentField.nullable) {
                        this.selectOption(first(this.currentField.options));
                    }

                    return;
                } else if (
                    currentSelectedOption &&
                    selectedOption &&
                    ["create", "attach"].includes(this.editMode)
                ) {
                    this.selectOption(selectedOption);

                    return;
                }

                this.selectOption(currentSelectedOption);
            },
        },

        computed: {
            isSearchable() {
                return this.currentField.searchable;
            },

            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },

            hasValue() {
                return Boolean(
                    !(
                        this.value === undefined ||
                        this.value === null ||
                        this.value === ""
                    ),
                );
            },
        },
    };
</script>
