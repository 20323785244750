<template>
    <component
        :is="as"
        :class="`v-button v-button-${this.severity}:${this.variant}`"
        :disabled="this.disabled || this.loading ? true : undefined"
        :aria-busy="loading || undefined"
        :data-size="size === 'md' ? undefined : size"
        :data-padding="padding === 'default' ? undefined : padding"
    >
        <span
            class="v-button-content"
            :class="{
                'v-button-loading': loading,
            }"
        >
            <icon
                v-if="icon"
                :name="icon"
                class="v-button-icon"
                :class="iconClass"
            />
            <span
                v-if="$slots.default || label"
                class="v-button-label"
            >
                <slot>
                    {{ label }}
                </slot>
            </span>
            <icon
                v-if="trailingIcon"
                :name="trailingIcon"
                class="v-button-icon"
            />
        </span>
        <span
            v-if="loading"
            class="v-button-loader"
        >
            <component-loader :width="loaderSize" />
        </span>
    </component>
</template>

<script>
    export default {
        props: {
            as: {
                type: String,
                default: "button",
            },
            size: {
                type: String,
                default: "md",
                validator: (v) => ["xs", "sm", "md", "lg"].includes(v),
            },
            label: {
                type: [String, Number],
            },
            variant: {
                type: String,
                default: "solid",
                validator: (v) =>
                    ["solid", "ghost", "outline", "link", "action"].includes(v),
            },
            severity: {
                type: String,
                default: "primary",
            },
            padding: {
                type: String,
                default: "default",
                validator: (v) => ["default", "tight"].includes(v),
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            icon: String,
            iconClass: [String, Array, Object],
            trailingIcon: String,
        },
        computed: {
            loaderSize() {
                return {
                    xs: 26,
                    sm: 28,
                    md: 30,
                    lg: 32,
                }[this.size];
            },
        },
    };
</script>
