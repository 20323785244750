<template>
    <filter-container :filter="filter">
        <template #filter>
            <input
                v-bind="extraAttributes"
                class="form-control"
                @input="handleChange"
                :value="value"
                :id="currentField.uniqueKey"
                :dusk="`${currentField.attribute}-filter`"
            />
        </template>
    </filter-container>
</template>

<script>
    import InputField from "./InputField.vue";

    export default {
        extends: InputField,

        computed: {
            defaultFieldType() {
                return "email";
            },
        },
    };
</script>
