<template>
    <div
        class="bg-blue-900 text-white rounded-t-lg flex border-b border-gray-300 dark:border-gray-700"
    >
        <div
            class="bg-clip w-48 uppercase font-semibold text-xxs tracking-wide px-3 py-2"
        >
            {{ keyLabel }}
        </div>

        <div
            class="bg-clip flex-grow uppercase font-semibold text-xxs tracking-wide px-3 py-2 border-l border-blue-800 dark:border-gray-700"
        >
            {{ valueLabel }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            keyLabel: {
                type: String,
            },
            valueLabel: {
                type: String,
            },
        },
    };
</script>
