<template>
    <filter-container :filter="filter">
        <template #filter>
            <!-- Search Input -->
            <select-dropdown
                v-if="isSearchable"
                :dusk="`${currentField.attribute}-search-filter`"
                @clear="value = null"
                @select="value = $event.value"
                @open="$emit('open')"
                @close="$emit('close')"
                :value="value"
                :options="currentField.options"
                searchable
                clearable
            />

            <!-- Select Input Field -->
            <select-control
                v-else
                :dusk="currentField.attribute"
                v-model:selected="value"
                @change="value = $event"
                :options="currentField.options"
            >
                <option
                    value=""
                    selected
                >
                    {{ placeholder }}
                </option>
            </select-control>
        </template>
    </filter-container>
</template>

<script>
    import FilterField from "@nova/extendables/FilterField.vue";

    export default {
        extends: FilterField,

        emits: ["open", "close"],

        computed: {
            isSearchable() {
                return this.filter?.searchable || this.currentField.searchable;
            },

            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },
        },
    };
</script>
