<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <icon
                v-if="field.value"
                :name="field.value"
            />
            <span v-else>{{ __("&mdash;") }}</span>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
