<template>
    <icon
        name="icomoon-spinner8"
        class="animate-spin text-blue-800"
        :class="{
            'h-1/4 w-1/4': variant === 'overlay',
            'h-3.5 w-3.5': variant === 'inline',
        }"
    />
</template>

<script>
    export default {
        props: {
            variant: {
                type: String,
                default: "overlay",
                validator: (v) => ["overlay", "inline"].includes(v),
            },
        },
    };
</script>
