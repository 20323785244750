<template>
    <filter-container :filter="filter">
        <template #filter>
            <input
                v-bind="extraAttributes"
                class="form-control"
                @input="value = $event.target.value"
                :value="value"
                :id="currentField.uniqueKey"
                :dusk="`${currentField.attribute}-filter`"
                :list="`${currentField.uniqueKey}-list`"
            />

            <datalist
                v-if="currentField.suggestions?.length > 0 > 0"
                :id="`${currentField.uniqueKey}-list`"
            >
                <option
                    v-for="suggestion in currentField.suggestions"
                    :key="suggestion"
                    :value="suggestion"
                />
            </datalist>
        </template>
    </filter-container>
</template>

<script>
    import InputField from "./InputField.vue";

    export default {
        extends: InputField,
    };
</script>
