<template>
    <component
        :is="isRemote ? 'a' : 'link'"
        :href="resourceUrl"
        class="link-default"
    >
        {{ resourceTitle }}
    </component>
</template>

<script>
    export default {
        props: {
            resourceName: {
                type: String,
                required: true,
            },
            resourceTitle: {
                type: String,
                required: true,
            },
            resourceId: {
                type: [Number, String],
                required: true,
            },
        },

        computed: {
            resourceInformation() {
                return Nova.resourceInformation(this.resourceName);
            },

            isRemote() {
                return Boolean(this.resourceInformation.routeToDetail);
            },

            resourceUrl() {
                return this.isRemote
                    ? this.resourceInformation.routeToDetail.replace(
                          ":id",
                          this.resourceId,
                      )
                    : Nova.url(
                          `/resources/${this.resourceName}/${this.resourceId}`,
                      );
            },
        },
    };
</script>
