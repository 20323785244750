<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <icon
                :name="icon"
                :class="color"
            />
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
        computed: {
            icon() {
                return this.field.value
                    ? "mdi-check-circle"
                    : "heroicon-c-x-circle";
            },
            color() {
                return this.field.value ? "text-green-500" : "text-red-500";
            },
        },
    };
</script>
