<template>
    <tr
        :data-pivot-id="resource.id.pivotValue"
        :dusk="`${resource.id.value}-row`"
        class="group border-l border-r last:border-b border-gray-300"
        @click.stop.prevent="handleClick"
    >
        <!-- Resource Selection Checkbox -->
        <td
            v-if="shouldShowCheckboxes"
            class="w-[1%] white-space-nowrap px-4"
            @click.stop
        >
            <checkbox
                v-if="shouldShowCheckboxes"
                @change="toggleSelection"
                :model-value="checked"
                :dusk="`${resource.id.value}-checkbox`"
                :aria-label="
                    __('Select Resource :title', { title: resource.title })
                "
            />
        </td>

        <!-- Fields -->
        <td
            v-for="(field, index) in resource.fields"
            :key="field.uniqueKey"
            :class="{
                'whitespace-nowrap': !field.wrapping,
                'cursor-pointer': clickableRow,
            }"
        >
            <component
                :is="'index-' + field.component"
                :class="`text-${field.textAlign}`"
                :field="field"
                :resource="resource"
                :resource-name="resourceName"
                :via-resource="viaResource"
                :via-resource-id="viaResourceId"
            />
        </td>

        <td class="w-[1%] white-space-nowrap text-center">
            <div class="flex items-center justify-center gap-1">
                <!-- Custom Actions -->
                <inline-action-dropdown
                    v-if="shouldShowActionDropdown"
                    :actions="availableActions"
                    :endpoint="actionsEndpoint"
                    :resource="resource"
                    :resource-name="resourceName"
                    :via-many-to-many="viaManyToMany"
                    :via-resource="viaResource"
                    :via-resource-id="viaResourceId"
                    :via-relationship="viaRelationship"
                    @actionExecuted="$emit('actionExecuted')"
                    @show-preview="navigateToPreviewView"
                />

                <!-- View Action -->
                <v-button
                    v-if="
                        authorizedToViewAnyResources &&
                        !(
                            authorizedToUpdateAnyResources &&
                            resource.routeToDetail != null &&
                            resource.routeToDetail == resource.routeToUpdate
                        )
                    "
                    :as="resource.routeToDetail ? 'a' : 'link'"
                    padding="tight"
                    size="sm"
                    icon="mdi-eye"
                    :href="resource.routeToDetail || viewURL"
                    :title="__('View')"
                    :aria-label="__('View')"
                    :dusk="`${resource['id'].value}-view-button`"
                    :disabled="!resource.authorizedToView"
                    @click.stop
                />

                <!-- Edit Action -->
                <v-button
                    v-if="authorizedToUpdateAnyResources"
                    :as="resource.routeToUpdate ? 'a' : 'link'"
                    padding="tight"
                    size="sm"
                    icon="mdi-pencil"
                    :href="resource.routeToUpdate || updateURL"
                    :title="viaManyToMany ? __('Edit Attached') : __('Edit')"
                    :aria-label="
                        viaManyToMany ? __('Edit Attached') : __('Edit')
                    "
                    :dusk="
                        viaManyToMany
                            ? `${resource['id'].value}-edit-attached-button`
                            : `${resource['id'].value}-edit-button`
                    "
                    :disabled="!resource.authorizedToUpdate"
                    @click.stop
                />

                <!-- Delete Action -->
                <v-button
                    v-if="
                        authorizedToDeleteAnyResources &&
                        (!resource.softDeleted || viaManyToMany)
                    "
                    severity="alert"
                    padding="tight"
                    size="sm"
                    icon="mdi-trash-can"
                    :title="__(viaManyToMany ? 'Detach' : 'Delete')"
                    :aria-label="__(viaManyToMany ? 'Detach' : 'Delete')"
                    :dusk="`${resource.id.value}-delete-button`"
                    :disabled="!resource.authorizedToDelete"
                    @click.stop="$emit('promptDelete', resource)"
                />

                <!-- Restore Action -->
                <v-button
                    v-if="
                        authorizedToRestoreAnyResources &&
                        resource.softDeleted &&
                        !viaManyToMany
                    "
                    severity="alert"
                    padding="tight"
                    size="sm"
                    icon="mdi-restore"
                    :title="__('Restore')"
                    :aria-label="__('Restore')"
                    :dusk="`${resource.id.value}-restore-button`"
                    @click.stop="$emit('promptRestore', resource)"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import filter from "lodash/filter";
    import { router } from "@inertiajs/vue3";
    import { mapGetters } from "vuex";

    export default {
        emits: ["actionExecuted", "promptDelete", "promptRestore"],

        inject: [
            "resourceHasId",
            "authorizedToViewAnyResources",
            "authorizedToUpdateAnyResources",
            "authorizedToDeleteAnyResources",
            "authorizedToRestoreAnyResources",
        ],

        props: [
            "actionsAreAvailable",
            "actionsEndpoint",
            "checked",
            "clickAction",
            "queryString",
            "relationshipType",
            "resource",
            "resourceName",
            "resourcesSelected",
            "selectedResources",
            "shouldShowCheckboxes",
            "shouldShowColumnBorders",
            "testId",
            "updateSelectionStatus",
            "viaManyToMany",
            "viaRelationship",
            "viaResource",
            "viaResourceId",
        ],

        data: () => ({
            commandPressed: false,
            previewModalOpen: false,
        }),

        beforeMount() {
            this.isSelected =
                this.selectedResources.indexOf(this.resource) > -1;
        },

        mounted() {
            window.addEventListener("keydown", this.handleKeydown);
            window.addEventListener("keyup", this.handleKeyup);
        },

        beforeUnmount() {
            window.removeEventListener("keydown", this.handleKeydown);
            window.removeEventListener("keyup", this.handleKeyup);
        },

        methods: {
            toggleSelection() {
                this.updateSelectionStatus(this.resource);
            },

            handleKeydown(e) {
                if (e.key === "Meta" || e.key === "Control") {
                    this.commandPressed = true;
                }
            },

            handleKeyup(e) {
                if (e.key === "Meta" || e.key === "Control") {
                    this.commandPressed = false;
                }
            },

            handleClick(e) {
                if (this.resourceHasId === false) {
                    return;
                } else if (this.clickAction === "edit") {
                    return this.navigateToEditView(e);
                } else if (this.clickAction === "select") {
                    return this.toggleSelection();
                } else if (this.clickAction === "ignore") {
                    return;
                } else if (this.clickAction === "detail") {
                    return this.navigateToDetailView(e);
                } else if (this.clickAction === "preview") {
                    return this.navigateToPreviewView(e);
                } else {
                    return this.navigateToDetailView(e);
                }
            },

            navigateToDetailView(e) {
                if (!this.resource.authorizedToView) {
                    return;
                }
                this.commandPressed
                    ? window.open(this.viewURL, "_blank")
                    : router.visit(this.viewURL);
            },

            navigateToEditView(e) {
                if (!this.resource.authorizedToUpdate) {
                    return;
                }
                this.commandPressed
                    ? window.open(this.updateURL, "_blank")
                    : router.visit(this.updateURL);
            },

            navigateToPreviewView(e) {
                if (!this.resource.authorizedToView) {
                    return;
                }
                this.openPreviewModal();
            },

            openPreviewModal() {
                this.previewModalOpen = true;
            },

            closePreviewModal() {
                this.previewModalOpen = false;
            },
        },

        computed: {
            ...mapGetters(["currentUser"]),

            updateURL() {
                if (this.viaManyToMany) {
                    return this.$url(
                        `/resources/${this.viaResource}/${this.viaResourceId}/edit-attached/${this.resourceName}/${this.resource.id.value}`,
                        {
                            viaRelationship: this.viaRelationship,
                            viaPivotId: this.resource.id.pivotValue,
                        },
                    );
                }

                return this.$url(
                    `/resources/${this.resourceName}/${this.resource.id.value}/edit`,
                    {
                        viaResource: this.viaResource,
                        viaResourceId: this.viaResourceId,
                        viaRelationship: this.viaRelationship,
                    },
                );
            },

            viewURL() {
                return this.$url(
                    `/resources/${this.resourceName}/${this.resource.id.value}`,
                );
            },

            availableActions() {
                return filter(this.resource.actions, (a) => a.showOnTableRow);
            },

            clickableRow() {
                if (this.resourceHasId === false) {
                    return false;
                } else if (this.clickAction === "edit") {
                    return this.resource.authorizedToUpdate;
                } else if (this.clickAction === "select") {
                    return this.shouldShowCheckboxes;
                } else if (this.clickAction === "ignore") {
                    return false;
                } else if (this.clickAction === "detail") {
                    return this.resource.authorizedToView;
                } else if (this.clickAction === "preview") {
                    return this.resource.authorizedToView;
                } else {
                    return this.resource.authorizedToView;
                }
            },

            shouldShowActionDropdown() {
                return (
                    this.availableActions.length > 0 || this.userHasAnyOptions
                );
            },

            /**
             * @link https://gitlab.eaglemetal.com/web-developers/packages/nova-vue/-/issues/20
             */
            shouldShowPreviewLink() {
                return (
                    false && // @todo Preview Modal
                    this.resource.authorizedToView &&
                    this.resource.previewHasFields
                );
            },

            userHasAnyOptions() {
                // prettier-ignore
                return (
                    this.resourceHasId &&
                    (
                        /** @todo https://gitlab.eaglemetal.com/web-developers/packages/nova-vue/-/issues/17 */
                        (this.resource.authorizedToReplicate && false) ||
                        this.shouldShowPreviewLink ||
                        this.canBeImpersonated
                    )
                );
            },

            /** @todo https://gitlab.eaglemetal.com/web-developers/packages/nova-vue/-/issues/61 */
            canBeImpersonated() {
                return (
                    false &&
                    this.currentUser.canImpersonate &&
                    this.resource.authorizedToImpersonate
                );
            },
        },
    };
</script>
