<script>
    import FilterField from "@nova/extendables/FilterField.vue";

    export default {
        extends: FilterField,
        computed: {
            defaultFieldType() {
                return "text";
            },
            defaultAttributes() {
                return {
                    type: this.currentField.type || this.defaultFieldType,
                    placeholder: this.currentField.placeholder,
                    min: this.currentField.min,
                    max: this.currentField.max,
                    step: this.currentField.step,
                    pattern: this.currentField.pattern,
                };
            },

            extraAttributes() {
                const attrs = this.currentField.extraAttributes;

                return {
                    ...this.defaultAttributes,
                    ...attrs,
                };
            },
        },
    };
</script>
