<template>
    <default-field :field="field">
        <template #field>
            <code-editor
                class="w-full h-auto"
                :language="field.language"
                v-model="value"
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,
    };
</script>
