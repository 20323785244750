<template>
    <div
        class="flex flex-col"
        :class="panelWidthClass"
    >
        <slot name="header">
            <div
                v-if="panel.name != pageTitle"
                class="flex items-center"
            >
                <heading
                    :level="2"
                    v-text="panel.name"
                    :dusk="`${panel.name}-detail-heading`"
                />

                <button
                    v-if="panel.collapsable"
                    @click="toggleCollapse"
                    class="h-6 w-6 ml-1 focus:outline-none focus:ring-none"
                    :aria-label="__('Toggle Collapsed')"
                    :aria-expanded="collapsed === false ? 'true' : 'false'"
                >
                    <collapse-button :collapsed="collapsed" />
                </button>

                <help-text-tooltip
                    v-if="panel.helpText"
                    class="ml-auto"
                    :text="panel.helpText"
                />
            </div>
        </slot>
        <card v-if="!collapsed">
            <field-grid :stacked="panel.stacked">
                <component
                    v-for="(field, index) in fields"
                    :key="index"
                    :is="resolveComponentName(field)"
                    :index="index"
                    :field="field"
                    :resource-id="resourceId"
                    :resource="resource"
                    @actionExecuted="actionExecuted"
                />
            </field-grid>
        </card>
    </div>
</template>

<script>
    import CollapsiblePanel from "@nova/extendables/CollapsiblePanel.vue";

    export default {
        extends: CollapsiblePanel,

        inject: ["pageTitle"],

        methods: {
            resolveComponentName(field) {
                return field.prefixComponent
                    ? "detail-" + field.component
                    : field.component;
            },

            showAllFields() {
                return (this.panel.limit = 0);
            },
        },

        computed: {
            fields() {
                if (this.panel.limit > 0) {
                    return this.panel.fields.slice(0, this.panel.limit);
                }

                return this.panel.fields;
            },

            shouldShowShowAllFieldsButton() {
                return this.panel.limit > 0;
            },
        },
    };
</script>
