<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <div
                v-if="field.value"
                class="rounded-lg inline-flex items-center justify-center border border-gray-400"
            >
                <div
                    class="w-6 h-6 rounded-md"
                    :style="{
                        backgroundColor: field.value,
                    }"
                />
            </div>
            <span v-else>{{ __("&mdash;") }}</span>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
