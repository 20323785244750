<template>
    <help-text-tooltip
        :title="title"
        :text="distributionText"
        icon="mdi-chart-bell-curve"
    />
</template>

<script>
    import { formatNumber } from "@nova/utils";

    export default {
        props: {
            mean: Number,
            std: Number,
            population: Number,
            format: Object,
            range: Object,
            title: {
                type: String,
                default: "Distribution",
            },
        },
        computed: {
            distributionText() {
                return `
                    <table class="mb-0">
                        <tbody>
                            <tr>
                                <th class="p-0 pr-1 text-left">Mean/Average (&mu;)</th>
                                <td class="p-0">${this.formattedMean}</td>
                            </tr>
                            <tr style="color: ${this.stdColor}">
                                <th class="p-0 pr-1 text-left">Standard Deviation (&sigma;)</th>
                                <td class="p-0">${this.formattedStd}</td>
                            </tr>
                            <tr>
                                <th class="p-0 pr-1 text-left">Population Size (n)</th>
                                <td class="p-0">${this.formattedPop}</td>
                            </tr>
                            ${this.rangeRows}
                        </tbody>
                    </table>
                `;
            },
            rangeRows() {
                if (!this.range) {
                    return "";
                }

                const interval = formatNumber(this.range.length, {
                    output: "time",
                    unit: this.range.unit,
                });

                const start = this.formatDate(this.range.start);
                const end = this.formatDate(this.range.end);

                return `
                    <tr>
                        <th class="p-0 pr-1 text-left">Range</th>
                        <td class="p-0">${interval}</td>
                    </tr>
                    <tr>
                        <th class="p-0 pr-1 text-left">From</th>
                        <td class="p-0 whitespace-nowrap">${start}</td>
                    </tr>
                    <tr>
                        <th class="p-0 pr-1 text-left">To</th>
                        <td class="p-0 whitespace-nowrap">${end}</td>
                    </tr>
                `;
            },
            formattedMean() {
                return formatNumber(this.mean, this.format);
            },
            formattedStd() {
                return formatNumber(this.std, this.format);
            },
            formattedPop() {
                return formatNumber(this.population);
            },
            stdColor() {
                return this.sufficientStd
                    ? this.colors.text
                    : this.colors.decrease;
            },
            popColor() {
                return this.sufficientPopulation
                    ? this.colors.text
                    : this.colors.decrease;
            },
            sufficientStd() {
                return this.std > 0;
            },
            sufficientPopulation() {
                return this.population >= 30;
            },
            colors() {
                const theme = this.$pluginOptions.theme;

                return {
                    primary: theme.colors.blue["500"],
                    secondary: theme.colors.orange["500"],
                    grid: theme.colors.gray["300"],
                    text: theme.colors.gray["900"],
                    muted: theme.colors.gray["500"],
                    increase: theme.colors.green["600"],
                    decrease: theme.colors.red["600"],
                };
            },
        },
        methods: {
            formatDate(date) {
                return dayjs(date).format("dddd, MMMM Do, YYYY, h:mm:ss A (z)");
            },
        },
    };
</script>
