<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <span v-if="field.viewable && field.value">
                <relation-peek
                    v-if="field.peekable && field.hasFieldsToPeekAt"
                    :resource-name="field.resourceName"
                    :resource-id="field.belongsToId"
                    :resource-title="field.value"
                    :resource-url="
                        $url(
                            `/resources/${field.resourceName}/${field.belongsToId}`,
                        )
                    "
                    :resource="resource"
                />

                <Link
                    v-else
                    :href="
                        $url(
                            `/resources/${field.resourceName}/${field.belongsToId}`,
                        )
                    "
                    class="link-default"
                >
                    {{ field.value }}
                </Link>
            </span>
            <p v-else-if="field.value">{{ field.value }}</p>
            <p v-else>{{ __("&mdash;") }}</p>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field", "resource"],
    };
</script>
