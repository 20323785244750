<template>
    <div class="flex items-center">
        <badge :class="typeClasses">
            <span class="mr-1 -ml-1">
                <component-loader
                    v-if="field.type == 'loading'"
                    width="20"
                    class="mr-1"
                />
                <icon
                    v-if="field.type == 'failed'"
                    name="mdi-exclamation-circle"
                />
                <icon
                    v-if="field.type == 'success'"
                    name="heroicon-c-check-circle"
                />
            </span>
            {{ fieldValue }}
        </badge>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,

        props: ["resourceName", "field"],

        computed: {
            typeClasses() {
                return [
                    this.field.textAlign === "center" && "mx-auto",
                    this.field.textAlign === "right" && "ml-auto mr-0",
                    this.field.textAlign === "left" && "ml-0 mr-auto",
                    this.field.typeClass,
                ];
            },
        },
    };
</script>
