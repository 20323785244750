<template>
    <filter-container :filter="filter">
        <template #filter>
            <select-dropdown
                :dusk="`${currentField.uniqueKey}-filter`"
                :options="currentField.options"
                :value="selected"
                :placeholder="placeholder"
                @select="handleSelect"
                @clear="value = ''"
                @open="$emit('open')"
                @close="handleClose"
                multiple
            />
        </template>
    </filter-container>
</template>

<script>
    import FilterField from "@nova/extendables/FilterField.vue";
    import filter from "lodash/filter";

    export default {
        extends: FilterField,

        emits: ["open", "close"],

        data: () => ({
            selected: "",
        }),

        watch: {
            value(newValue) {
                this.selected = this.clone(newValue);
            },
        },

        methods: {
            handleSelect(option) {
                if (!this.selected) {
                    this.selected = [];
                }

                const index = this.selected.findIndex(
                    (v) => v === option.value,
                );

                if (index > -1) {
                    this.selected.splice(index, 1);
                } else {
                    this.selected.push(option.value);
                }

                if (this.selected.length === 0) {
                    this.selected = "";
                }
            },

            handleClose() {
                this.value = this.clone(this.selected);
                this.$emit("close");
            },

            castValue(value) {
                return value && typeof value === "string"
                    ? value.split(",")
                    : value;
            },

            clone(values) {
                return JSON.parse(JSON.stringify(values));
            },
        },

        computed: {
            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },

            debounceWait() {
                return 1;
            },
        },
    };
</script>
