<template>
    <default-field
        :field="field"
        :error="error"
    >
        <template #field>
            <input
                v-bind="extraAttributes"
                class="form-control"
                @input="handleChange"
                :value="value"
                :id="field.uniqueKey"
                :dusk="field.attribute"
                :disabled="currentlyIsReadonly"
                :maxlength="field.enforceMaxlength ? field.maxlength : -1"
            />

            <datalist
                v-if="suggestions.length > 0"
                :id="suggestionsId"
            >
                <option
                    v-for="suggestion in suggestions"
                    :key="suggestion"
                    :value="suggestion"
                />
            </datalist>

            <character-counter
                v-if="field.maxlength"
                :count="value?.length || 0"
                :limit="parseInt(field.maxlength)"
            />
        </template>
    </default-field>
</template>

<script>
    import InputField from "./InputField.vue";

    export default {
        extends: InputField,
    };
</script>
