<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value="{ item }">
            <image-loader
                v-if="shouldShowLoader"
                :src="imageUrl"
                :maxWidth="parseInt(field.detailWidth || field.maxWidth)"
                :rounded="field.rounded"
                :aspect="field.aspect"
            />

            <span
                v-if="item.fieldValue && !imageUrl"
                class="break-words"
            >
                {{ item.fieldValue }}
            </span>

            <span v-if="!item.fieldValue && !imageUrl">{{
                __("&mdash;")
            }}</span>
        </template>
        <template #actions>
            <a
                v-if="downloadable"
                :dusk="field.attribute + '-download-link'"
                @keydown.enter.prevent="download"
                @click.prevent="download"
                tabindex="0"
                class="link-default"
                :title="__('Download')"
            >
                <icon
                    class="w-4 h-4"
                    name="mdi-download"
                />
            </a>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],

        methods: {
            download() {
                let link = document.createElement("a");
                link.href = this.imageUrl; // @todo Download API
                link.target = "_blank";
                link.download = "download";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        },

        computed: {
            hasValue() {
                return Boolean(this.field.value || this.imageUrl);
            },

            shouldShowLoader() {
                return this.imageUrl;
            },

            downloadable() {
                return Boolean(this.field.downloadable && this.imageUrl);
            },

            imageUrl() {
                return this.field.previewUrl || this.field.thumbnailUrl;
            },
        },
    };
</script>
