<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #field-value="{ item }">
            <a
                :href="`mailto:${item.field.value}`"
                class="link-default"
            >
                {{ item.fieldValue }}
            </a>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
