<script>
    import Field from "./Field.vue";
    import debounce from "lodash/debounce";

    export default {
        extends: Field,
        emits: ["change", "update:modelValue"],

        props: {
            field: Object,
            modelValue: {},
            resourceName: String,
            filterKey: String,
            lens: String,
        },

        data() {
            return {
                value: null,
                debouncedHandleChange: null,
            };
        },

        watch: {
            modelValue(newValue) {
                const casted = this.castValue(newValue);

                if (casted === this.value) {
                    return;
                }

                this.value = casted;
            },

            value(newValue, oldValue) {
                this.debouncedHandleChange();
            },
        },

        created() {
            this.debouncedHandleChange = debounce(
                () => this.handleChange(),
                this.debounceWait,
            );

            if (this.filterValue) {
                this.setCurrentFilterValue();
            }
        },

        mounted() {
            Nova.$on("filter-reset", this.setCurrentFilterValue);
        },

        beforeUnmount() {
            Nova.$off("filter-reset", this.setCurrentFilterValue);
        },

        methods: {
            setCurrentFilterValue() {
                this.value = this.castValue(this.filterValue);
            },

            handleChange(event) {
                this.emitChange();
            },

            emitChange() {
                this.$emit("change", {
                    filterClass: this.filterKey,
                    field: this.currentField,
                    value: this.emitValue,
                });
            },

            castValue(value) {
                return value;
            },
        },

        computed: {
            emitValue() {
                return this.value;
            },

            filter() {
                if (!this.resourceName) {
                    return {
                        field: this.field,
                    };
                }

                return this.$store.getters[`${this.resourceName}/getFilter`](
                    this.filterKey,
                );
            },

            currentField() {
                return this.filter?.field || this.field;
            },

            filterValue() {
                const value = this.filter
                    ? this.filter.currentValue
                    : this.field?.value;

                if (Array.isArray(value)) {
                    return value.map((v) => (v === null ? null : String(v)));
                }

                return value === null ? null : String(value);
            },

            debounceWait() {
                return 500;
            },
        },
    };
</script>
