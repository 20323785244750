<template>
    <span v-if="!error">
        <img
            :class="classes"
            :style="styles"
            :src="src"
            @load="handleLoad"
            @error="handleError"
        />
    </span>
    <a
        v-else
        :href="src"
    >
        <icon
            name="heroiron-c-exclamation-circle"
            class="text-red-500"
            v-tooltip="__('The image could not be loaded.')"
        />
    </a>
</template>

<script>
    export default {
        props: {
            src: {
                type: String,
                required: true,
            },
            maxWidth: {
                type: Number,
                default: 320,
            },
            rounded: {
                type: Boolean,
                default: false,
            },
            aspect: {
                type: String,
                default: "aspect-auto",
                validator: (v) => ["aspect-auto", "aspect-square"].includes(v),
            },
        },

        data: () => ({
            loaded: false,
            error: false,
        }),

        methods: {
            handleLoad() {
                this.loaded = true;
            },

            handleError() {
                this.error = true;
                console.warn(`The image could not be loaded: ${this.src}`);
            },
        },

        computed: {
            classes() {
                return this.rounded && "rounded-full";
            },
            styles() {
                return {
                    "max-width": `${this.maxWidth}px`,
                    ...(this.aspect === "aspect-square" && {
                        width: `${this.maxWidth}px`,
                    }),
                    ...(this.aspect === "aspect-square" && {
                        height: `${this.maxWidth}px`,
                    }),
                };
            },
        },
    };
</script>
