<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <form-key-value-table
                v-if="theData.length > 0"
                :edit-mode="false"
                class="overflow-hidden"
            >
                <form-key-value-header
                    :key-label="field.keyLabel"
                    :value-label="field.valueLabel"
                />

                <div
                    class="bg-gray-50 dark:bg-gray-700 overflow-hidden key-value-items"
                >
                    <form-key-value-item
                        v-for="(item, index) in theData"
                        :index="index"
                        :item="item"
                        :disabled="true"
                        :key="item.key"
                    />
                </div>
            </form-key-value-table>
        </template>
    </panel-item>
</template>

<script>
    import map from "lodash/map";

    export default {
        props: ["index", "field"],

        data: () => ({ theData: [] }),

        created() {
            this.theData = map(
                Object.entries(this.field.value || {}),
                ([key, value]) => ({
                    key: `${key}`,
                    value,
                }),
            );
        },
    };
</script>
