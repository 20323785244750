<template>
    <default-field :field="field">
        <template #field>
            <code-editor
                class="w-full h-auto"
                language="json"
                v-model="value"
                @change="handleCodeChange"
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,

        computed: {
            emitValue() {
                return JSON.parse(this.value);
            },
        },

        methods: {
            handleCodeChange() {
                try {
                    JSON.parse(this.value);
                } catch (e) {
                    return;
                }

                this.updateModelValue();
            },
            castValue(value) {
                return JSON.stringify(value, null, 2);
            },
        },
    };
</script>
