<template>
    <icon
        name="mdi-chevron-down"
        class="transform transition-all"
        :class="{ '-rotate-90': collapsed }"
    />
</template>

<script>
    export default {
        props: {
            collapsed: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
