<template>
    <filter-container :filter="filter">
        <template #aside>
            <loader
                v-if="loading"
                variant="inline"
            ></loader>
        </template>

        <template #filter>
            <select-dropdown
                v-if="isSearchable"
                ref="searchable"
                :dusk="`${currentField.uniqueKey}-search-filter`"
                @input="performSearch"
                @clear="handleClearSelection"
                @open="handleShowingActiveSearchInput"
                @close="$emit('close')"
                @select="selectResource"
                :debounce="currentField.debounce"
                :value="selectedResource?.value"
                :options="availableResources"
                :with-subtitles="currentField.withSubtitles"
                display-by="display"
                :clearable="true"
                searchable
            />

            <select-control
                v-else
                :dusk="`${currentField.uniqueKey}-filter`"
                v-model:selected="selectedResourceId"
                @change="selectedResourceId = $event"
                :options="availableResources"
                label="display"
            >
                <option
                    value=""
                    selected
                >
                    {{ __("&mdash;") }}
                </option>
            </select-control>
        </template>
    </filter-container>
</template>

<script>
    import FilterField from "@nova/extendables/FilterField.vue";
    import debounce from "lodash/debounce";
    import filled from "@nova/utils/filled";
    import find from "lodash/find";
    import isNil from "lodash/isNil";

    export default {
        extends: FilterField,

        emits: ["open", "close"],

        data: () => ({
            search: "",
            selectedResource: null,
            selectedResourceId: null,
            availableResources: [],
            softDeletes: false,
            withTrashed: false,
            loading: false,
            open: false,
        }),

        mounted() {
            this.initializeComponent();
        },

        created() {
            this.debouncedHandleChange = debounce(
                () => this.handleChange(),
                500,
            );

            Nova.$on("filter-active", this.handleClosingInactiveSearchInputs);
        },

        beforeUnmount() {
            Nova.$off("filter-active", this.handleClosingInactiveSearchInputs);
        },

        watch: {
            selectedResource(resource) {
                this.selectedResourceId = filled(resource)
                    ? resource.value
                    : "";
            },

            selectedResourceId(newValue) {
                this.value = newValue;
            },
        },

        methods: {
            selectResource(resource) {
                this.selectedResource = resource;
                this.selectedResourceId = resource.value;

                if (this.currentField) {
                    if (typeof this["emitFieldValueChange"] == "function") {
                        this.emitFieldValueChange(
                            this.currentField.attribute,
                            this.selectedResourceId,
                        );
                    } else {
                        Nova.$emit(
                            this.currentField.attribute + "-change",
                            this.selectedResourceId,
                        );
                    }
                }
            },

            handleSearchCleared() {
                this.availableResources = [];
            },

            clearSelection() {
                this.selectedResource = null;
                this.selectedResourceId = null;
                this.availableResources = [];

                if (this.currentField) {
                    if (typeof this["emitFieldValueChange"] == "function") {
                        this.emitFieldValueChange(
                            this.currentField.attribute,
                            null,
                        );
                    } else {
                        Nova.$emit(
                            this.currentField.attribute + "-change",
                            null,
                        );
                    }
                }
            },

            performSearch(search) {
                this.search = search;

                const trimmedSearch = search.trim();
                // If the user performs an empty search, it will load all the results
                // so let's just set the availableResources to an empty array to avoid
                // loading a huge result set
                if (trimmedSearch == "") {
                    return;
                }

                this.searchDebouncer(() => {
                    this.getAvailableResources(trimmedSearch);
                }, 500);
            },

            searchDebouncer: debounce((callback) => callback(), 500),

            initializeComponent() {
                let filter = this.filter;
                let shouldSelectInitialResource = false;

                if (this.filter.currentValue) {
                    this.selectedResourceId = this.filter.currentValue;

                    if (this.isSearchable === true) {
                        shouldSelectInitialResource = true;
                    }
                }

                if (!this.isSearchable || shouldSelectInitialResource) {
                    this.getAvailableResources().then(() => {
                        if (shouldSelectInitialResource === true) {
                            this.selectInitialResource();
                        }
                    });
                }
            },

            getAvailableResources(search) {
                this.loading = true;

                const queryParams = this.queryParams;

                if (!isNil(search)) {
                    queryParams.first = false;
                    queryParams.current = null;
                    queryParams.search = search;
                }

                return this.fetchAvailableResources(
                    this.currentField.resourceName,
                    {
                        params: queryParams,
                    },
                )
                    .then(
                        ({ data: { resources, softDeletes, withTrashed } }) => {
                            if (!this.isSearchable) {
                                this.withTrashed = withTrashed;
                            }

                            this.availableResources = resources;
                            this.softDeletes = softDeletes;
                        },
                    )
                    .finally(() => (this.loading = false));
            },

            fetchAvailableResources(resourceName, options) {
                return Nova.request().get(
                    `/nova-api/${resourceName}/search`,
                    options,
                );
            },

            determineIfSoftDeletes(resourceName) {
                return Nova.request().get(
                    `/nova-api/${resourceName}/soft-deletes`,
                );
            },

            selectInitialResource() {
                this.selectedResource = find(
                    this.availableResources,
                    (r) => r.value === this.selectedResourceId,
                );
            },

            handleShowingActiveSearchInput() {
                this.open = true;
                Nova.$emit("filter-active", this.filterKey);
                this.$emit("open");
            },

            closeSearchableRef() {
                if (this.$refs.searchable) {
                    this.$refs.searchable.close();
                    this.open = false;
                }
            },

            handleClosingInactiveSearchInputs(key) {
                if (!this.open) {
                    return;
                }

                if (key !== this.filterKey) {
                    this.closeSearchableRef();
                }
            },

            handleClearSelection() {
                this.clearSelection();
            },

            setCurrentFilterValue() {
                if (this.filter.currentValue !== "") {
                    return;
                }

                this.selectedResourceId = "";
                this.selectedResource = null;
                this.availableResources = [];

                this.closeSearchableRef();

                this.initializeComponent();
            },
        },

        computed: {
            isSearchable() {
                return this.currentField.searchable;
            },

            queryParams() {
                return {
                    current: this.selectedResourceId,
                    first: this.selectedResourceId && this.isSearchable,
                    search: this.search,
                    withTrashed: this.withTrashed,
                };
            },
        },
    };
</script>
