<template>
    <loading-card :loading="loading">
        <component
            v-if="attrs.data"
            :is="component"
            :title="card.name"
            v-bind="{
                ...$attrs,
                ...attrs,
            }"
        >
            <template
                v-if="$slots.controls"
                #controls
            >
                <slot
                    name="controls"
                    :card="card"
                ></slot>
            </template>
            <template #help>
                <help-text-tooltip
                    v-if="card.helpText"
                    :text="card.helpText"
                    :width="card.helpWidth"
                />
            </template>
        </component>

        <metric-error-dialog
            v-if="error"
            :name="card.name"
            :error="error"
            @click="fetch"
        />
    </loading-card>
</template>

<script>
    export default {
        props: {
            component: {
                type: String,
                required: true,
            },
            card: {
                type: Object,
                default: {},
            },
            params: {
                type: Object,
                default: {},
            },
        },
        data: () => ({
            loading: true,
            attrs: {},
            error: null,
        }),
        watch: {
            endpoint() {
                this.fetch();
            },
            payload() {
                this.fetch();
            },
        },
        methods: {
            fetch() {
                this.loading = true;
                this.error = null;

                this.$http
                    .get(this.card.endpoint, this.payload)
                    .then(({ data }) => {
                        const value = this.card.dataKey
                            ? data[this.card.dataKey]
                            : data;

                        this.attrs = value;
                    })
                    .catch((error) => {
                        this.error = error;
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
        computed: {
            payload() {
                return {
                    params: {
                        ...this.card.params,
                        ...this.params,
                    },
                };
            },
        },
        created() {
            this.fetch();
        },
    };
</script>
