<template>
    <div
        v-if="isNotObject"
        class="flex items-center key-value-item"
    >
        <div
            class="flex flex-grow border-b border-gray-300 dark:border-gray-700 key-value-fields"
        >
            <div
                class="flex-none w-48 cursor-text"
                :class="[
                    readOnlyKeys || !isEditable
                        ? 'bg-gray-50 dark:bg-gray-800'
                        : 'bg-white dark:bg-gray-900',
                ]"
            >
                <textarea
                    rows="1"
                    :dusk="`key-value-key-${index}`"
                    v-model="item.key"
                    @focus="handleKeyFieldFocus"
                    ref="keyField"
                    type="text"
                    class="form-control font-mono text-xs resize-none border-none shadow-none min-h-full"
                    :disabled="!isEditable || readOnlyKeys"
                    :tabindex="!isEditable || readOnlyKeys ? -1 : 0"
                />
            </div>

            <div
                @click="handleValueFieldFocus"
                class="flex-grow border-l border-gray-200 dark:border-gray-700"
                :class="[
                    readOnlyKeys || !isEditable
                        ? 'bg-gray-50 dark:bg-gray-700'
                        : 'bg-white dark:bg-gray-900',
                ]"
            >
                <textarea
                    rows="1"
                    :dusk="`key-value-value-${index}`"
                    v-model="item.value"
                    @focus="handleValueFieldFocus"
                    ref="valueField"
                    type="text"
                    class="form-control font-mono text-xs border-none shadow-none min-h-full"
                    :disabled="!isEditable"
                    :tabindex="!isEditable ? -1 : 0"
                />
            </div>
        </div>

        <div
            v-if="isEditable && canDeleteRow"
            class="flex justify-center h-11 w-11 absolute -right-[50px]"
        >
            <v-button
                @click="$emit('remove-row', item.id)"
                :dusk="`remove-key-value-${index}`"
                variant="link"
                severity="alert"
                tabindex="0"
                :title="__('Delete')"
                icon="mdi-minus-circle"
            />
        </div>
    </div>
</template>

<script>
    import autosize from "autosize";

    export default {
        emits: ["remove-row"],

        props: {
            index: Number,
            item: Object,
            disabled: {
                type: Boolean,
                default: false,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            readOnlyKeys: {
                type: Boolean,
                default: false,
            },
            canDeleteRow: {
                type: Boolean,
                default: true,
            },
        },

        mounted() {
            autosize(this.$refs.keyField);
            autosize(this.$refs.valueField);
        },

        methods: {
            handleKeyFieldFocus() {
                this.$refs.keyField.select();
            },

            handleValueFieldFocus() {
                this.$refs.valueField.select();
            },
        },

        computed: {
            isNotObject() {
                return !(this.item.value instanceof Object);
            },
            isEditable() {
                return !this.readOnly && !this.disabled;
            },
        },
    };
</script>
