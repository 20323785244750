<template>
    <v-button
        v-if="shouldShowButtons"
        :as="resourceInformation?.routeToCreate ? 'a' : 'link'"
        :variant="variant"
        :severity="severity"
        :icon="icon"
        :padding="padding"
        size="sm"
        :href="
            resourceInformation?.routeToCreate ||
            $url(`/resources/${resourceName}/new`, {
                viaResource: viaResource,
                viaResourceId: viaResourceId,
                viaRelationship: viaRelationship,
                relationshipType: relationshipType,
            })
        "
        :label="__('Create') + ' ' + resourceInformation?.singularLabel"
        dusk="create-button"
        tabindex="1"
        @click.stop
    />
</template>

<script>
    export default {
        inject: [
            "resourceInformation",
            "resourceName",
            "viaResource",
            "viaResourceId",
            "viaRelationship",
            "relationshipType",
        ],

        props: {
            type: {
                type: String,
                default: "button",
                validator: (v) => ["button", "outline"].includes(v),
            },
            authorizedToCreate: {
                type: Boolean,
                default: false,
            },
            authorizedToRelate: {
                type: Boolean,
                default: false,
            },
            alreadyFilled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            variant() {
                return this.type === "button" ? "solid" : "outline";
            },

            severity() {
                return this.type === "button" ? "muted" : "primary";
            },

            icon() {
                return this.type === "button" ? "mdi-plus" : null;
            },

            padding() {
                return this.type === "button" ? "tight" : "default";
            },

            shouldShowAttachButton() {
                return (
                    (this.relationshipType === "belongsToMany" ||
                        this.relationshipType === "morphToMany") &&
                    this.authorizedToRelate
                );
            },

            shouldShowCreateButton() {
                return (
                    this.authorizedToCreate &&
                    this.authorizedToRelate &&
                    !this.alreadyFilled
                );
            },

            shouldShowButtons() {
                return (
                    this.shouldShowAttachButton || this.shouldShowCreateButton
                );
            },
        },
    };
</script>
