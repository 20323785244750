import registerComponents from "../utils/registerComponents";

function registerGroups(app, options, groups) {
    groups.forEach(([prefix, files]) => {
        registerComponents(app, files, options, prefix);
    });
}

export default {
    install(app, options) {
        registerGroups(app, options, [
            ["", import.meta.glob("../components/**/*.vue", { eager: true })],
            ["", import.meta.glob("../layouts/**/*.vue", { eager: true })],
            [
                "Index",
                import.meta.glob("../fields/Index/*.vue", { eager: true }),
            ],
            [
                "Detail",
                import.meta.glob("../fields/Detail/*.vue", { eager: true }),
            ],
            ["Form", import.meta.glob("../fields/Form/*.vue", { eager: true })],
            [
                "Filter",
                import.meta.glob("../fields/Filter/*.vue", { eager: true }),
            ],
        ]);
    },
};
