<script>
    import { gridSpan } from "@nova/utils";

    export default {
        emits: ["actionExecuted"],

        props: ["resourceName", "resourceId", "resource", "panel"],

        methods: {
            actionExecuted() {
                this.$emit("actionExecuted");
            },
        },

        computed: {
            panelWidthClass() {
                return gridSpan(this.panel.width);
            },
        },
    };
</script>
