<template>
    <default-field
        :field="currentField"
        :errors="errors"
    >
        <template #field>
            <select-dropdown
                :dusk="field.attribute"
                :options="currentField.options"
                :searchable="currentField.searchable"
                :value="value"
                :placeholder="placeholder"
                @select="handleSelect"
                @clear="clearSelection"
                :clearable="currentField.nullable"
                :disabled="currentlyIsReadonly"
                multiple
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";
    import filter from "lodash/filter";
    import isNil from "lodash/isNil";
    import map from "lodash/map";
    import merge from "lodash/merge";

    export default {
        extends: DependentFormField,

        methods: {
            clearSelection() {
                this.value = this.getDefaultValue();

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }

                this.updateValue(this.value);
            },

            handleSelect(option) {
                if (isNil(option)) {
                    this.clearSelection();
                    return;
                }

                const index = this.value.findIndex((v) => v === option.value);

                if (index > -1) {
                    this.value.splice(index, 1);
                } else {
                    this.value.push(option.value);
                }

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }

                this.updateValue(this.value);
            },

            setInitialValue() {
                const values = !(
                    this.currentField.value === undefined ||
                    this.currentField.value === null ||
                    this.currentField.value === ""
                )
                    ? merge(this.currentField.value || [], this.value)
                    : this.value;

                const selectedOptions = filter(
                    this.currentField.options ?? [],
                    (v) => values.indexOf(v.value) >= 0,
                );

                this.value = map(selectedOptions, (o) => o.value);
            },

            getDefaultValue() {
                return [];
            },

            handleChange(value) {
                this.value = value;

                if (this.field) {
                    this.emitFieldValueChange(this.field.attribute, this.value);
                }

                this.updateValue(this.value);
            },

            onSyncedField() {
                this.setInitialValue();
            },
        },

        computed: {
            fillValue() {
                return JSON.stringify(this.value);
            },

            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },
        },
    };
</script>
