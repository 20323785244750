<template>
    <!-- Pagination -->
    <component
        :is="paginationComponent"
        :next="hasNextPage"
        :previous="hasPreviousPage"
        @load-more="loadMore"
        @page="selectPage"
        :pages="totalPages"
        :page="currentPage"
        :per-page="perPage"
        :resource-count-label="resourceCountLabel"
        :current-resource-count="currentResourceCount"
        :all-matching-resource-count="allMatchingResourceCount"
    >
        <span
            v-if="resourceCountLabel"
            class="text-xs px-4"
            :class="{
                'ml-auto hidden md:inline':
                    paginationComponent === 'pagination-links',
            }"
        >
            {{ resourceCountLabel }}
        </span>
    </component>
</template>

<script>
    export default {
        props: [
            "paginationComponent",
            "hasNextPage",
            "hasPreviousPage",
            "loadMore",
            "selectPage",
            "totalPages",
            "currentPage",
            "perPage",
            "resourceCountLabel",
            "currentResourceCount",
            "allMatchingResourceCount",
        ],
    };
</script>
