<template>
    <div :class="`text-${field.textAlign}`">
        <p
            v-if="fieldValue"
            class="inline-flex items-center gap-1"
            :class="alignClass"
            v-bind="$attrs"
        >
            <span v-if="!field.asHtml">
                <slot
                    name="field-value"
                    :item="this"
                >
                    {{ shouldFormat ? formatNumber(fieldValue) : fieldValue }}
                </slot>
            </span>
            <div
                v-else
                @click.stop
                v-html="fieldValue"
            />
            <copy-button
                v-if="fieldValue && field.copyable"
                @click.prevent.stop="copy"
            />
        </p>
        <p v-else>&mdash;</p>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";
    import { formatNumber } from "@nova/utils";

    export default {
        extends: DisplayField,

        inheritAttrs: false,

        methods: {
            formatNumber
        },

        computed: {
            shouldFormat() {
                return this.field.type === 'number';
            }
        }
    };
</script>
