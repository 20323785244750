<template>
    <loading-view :loading="loading">
        <cards :cards="cards" />
    </loading-view>
</template>

<script>
    export default {
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            params: {
                type: Object,
                default: {},
            },
            dataKey: {
                type: String,
                default: null,
            },
        },
        data: () => ({
            loading: true,
            cards: [],
            error: null,
        }),
        methods: {
            fetch() {
                this.loading = true;
                this.error = null;

                this.$http
                    .get(this.endpoint, this.payload)
                    .then(({ data }) => {
                        const value = this.dataKey ? data[this.dataKey] : data;

                        this.cards = value.cards;
                    })
                    .catch((error) => {
                        this.error = error;
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
        computed: {
            payload() {
                return {
                    params: this.params,
                };
            },
        },
        created() {
            this.fetch();
        },
    };
</script>
