<template>
    <filter-container :filter="filter">
        <template #filter>
            <select-control
                :dusk="`${currentField.uniqueKey}-filter`"
                v-model:selected="value"
                @change="value = $event"
                :options="currentField.morphToTypes"
                label="singularLabel"
            >
                <option
                    value=""
                    :selected="value === ''"
                >
                    {{ placeholder }}
                </option>
            </select-control>
        </template>
    </filter-container>
</template>

<script>
    import FilterField from "@nova/extendables/FilterField.vue";

    export default {
        extends: FilterField,

        computed: {
            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Choose an option")
                );
            },
        },
    };
</script>
