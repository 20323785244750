<template>
    <teleport to="body">
        <template v-if="show">
            <div
                v-bind="defaultAttributes"
                class="modal fixed inset-0 z-[60]"
                :class="{
                    'px-3 md:px-0 py-3 md:py-6 overflow-x-hidden overflow-y-auto':
                        modalStyle === 'window',
                    'h-full': modalStyle === 'fullscreen',
                }"
                :role="role"
                :data-modal-open="show"
                :aria-modal="show"
            >
                <div
                    class="@container/modal relative mx-auto z-20"
                    :class="contentClasses"
                    ref="modalContent"
                >
                    <div
                        class="mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
                    >
                        <modal-header
                            v-if="header"
                            @close="$emit('close')"
                        >
                            {{ header }}
                        </modal-header>
                        <slot />
                    </div>
                </div>
            </div>

            <div
                class="fixed inset-0 z-[55] bg-gray-800/75 dark:bg-gray-950/90"
                dusk="modal-backdrop"
            />
        </template>
    </teleport>
</template>

<script>
    import filter from "lodash/filter";
    import omit from "lodash/omit";
    import { mapMutations } from "vuex";
    import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";

    export default {
        emits: ["showing", "closing", "close"],
        inheritAttrs: false,
        props: {
            header: String,
            show: { type: Boolean, default: false },
            size: {
                type: String,
                default: "xl",
                validator: (v) =>
                    [
                        "sm",
                        "md",
                        "lg",
                        "xl",
                        "2xl",
                        "3xl",
                        "4xl",
                        "5xl",
                        "6xl",
                        "7xl",
                    ].includes(v),
            },
            modalStyle: { type: String, default: "window" },
            role: { type: String, default: "dialog" },
            useFocusTrap: { type: Boolean, default: true },
        },

        data: () => ({
            usesFocusTrap: true,
            activate: null,
            deactivate: null,
        }),

        mounted() {
            Nova.$on("disable-focus-trap", this.disableModalFocusTrap);
            Nova.$on("enable-focus-trap", this.enableModalFocusTrap);

            document.addEventListener("keydown", this.handleKeydown);

            if (this.show === true) {
                this.handleVisibilityChange(true);
            }
        },

        beforeUnmount() {
            document.body.classList.remove("overflow-hidden");
            Nova.resumeShortcuts();

            Nova.$off("disable-focus-trap", this.disableModalFocusTrap);
            Nova.$off("enable-focus-trap", this.enableModalFocusTrap);

            document.removeEventListener("keydown", this.handleKeydown);
            this.usesFocusTrap = false;
        },

        created() {
            const { activate, deactivate } = useFocusTrap(
                this.$refs.modalContent,
                {
                    immediate: false,
                    allowOutsideClick: true,
                    escapeDeactivates: false,
                },
            );

            this.activate = activate;
            this.deactivate = deactivate;
        },

        watch: {
            show(v) {
                this.handleVisibilityChange(v);
            },

            hasTrapFocus(enable) {
                try {
                    if (enable) {
                        this.$nextTick(() => this.activate());
                    } else {
                        this.deactivate();
                    }
                } catch (e) {
                    //
                }
            },
        },

        methods: {
            ...mapMutations(["allowLeavingModal"]),

            disableModalFocusTrap() {
                this.usesFocusTrap = false;
            },

            enableModalFocusTrap() {
                this.usesFocusTrap = true;
            },

            async handleVisibilityChange() {
                if (this.showing === true) {
                    this.$emit("showing");
                    document.body.classList.add("overflow-hidden");
                    Nova.pauseShortcuts();

                    this.usesFocusTrap = true;
                } else {
                    this.usesFocusTrap = false;

                    this.$emit("closing");
                    document.body.classList.remove("overflow-hidden");
                    Nova.resumeShortcuts();
                }

                this.allowLeavingModal();
            },

            handleKeydown(e) {
                if (e.key === "Escape" && this.show === true) {
                    this.$emit("close", e);
                }
            },
        },

        computed: {
            hasTrapFocus() {
                return this.useFocusTrap && this.usesFocusTrap === true;
            },

            defaultAttributes() {
                return omit(this.$attrs, ["class"]);
            },

            sizeClasses() {
                return {
                    sm: "max-w-sm",
                    md: "max-w-md",
                    lg: "max-w-lg",
                    xl: "max-w-xl",
                    "2xl": "max-w-2xl",
                    "3xl": "max-w-3xl",
                    "4xl": "max-w-4xl",
                    "5xl": "max-w-5xl",
                    "6xl": "max-w-6xl",
                    "7xl": "max-w-7xl",
                };
            },

            contentClasses() {
                const windowClasses =
                    this.modalStyle === "window" ? this.sizeClasses : {};

                return filter([
                    windowClasses[this.size] ?? null,
                    this.modalStyle === "fullscreen" ? "h-full" : "",
                    this.$attrs.class,
                ]);
            },
        },
    };
</script>
