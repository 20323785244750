<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #value>
            <badge
                class="mt-1"
                :label="field.label"
                :extra-classes="field.typeClass"
            >
                <template #icon>
                    <span
                        v-if="field.icon"
                        class="mr-1 -ml-1"
                    >
                        <icon
                            :name="field.icon"
                            class="w-4 h-4"
                        />
                    </span>
                </template>
            </badge>
        </template>
    </panel-item>
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
