<template>
    <panel-item
        :index="index"
        :field="field"
    />
</template>

<script>
    export default {
        props: ["index", "field"],
    };
</script>
