<template>
    <div :class="filterWrapperClasses">
        <div :class="labelWrapperClasses">
            <form-label
                :label="name"
                :sublabel="field?.sublabel"
            >
                <slot />
            </form-label>

            <div class="ml-auto relative flex items-center gap-1">
                <slot name="aside" />
                <help-text-tooltip
                    v-if="helpText"
                    :text="helpText"
                />
            </div>
        </div>

        <div :class="controlWrapperClasses">
            <slot name="filter" />
        </div>
    </div>
</template>

<script>
    import { gridSpan } from "@nova/utils";
    export default {
        props: {
            filter: Object,
        },

        inject: {
            stacked: {
                from: "stacked",
                default: false,
            },
            defaultWidth: {
                from: "defaultWidth",
                default: "full",
            },
        },

        computed: {
            name() {
                return this.filter?.name || this.filter?.field?.name;
            },

            helpText() {
                return this.filter?.helpText || this.filter?.field?.helpText;
            },

            width() {
                return this.filter?.width || this.filter?.field?.width;
            },

            shouldStack() {
                return (
                    this.stacked ||
                    this.filter?.stacked ||
                    this.filter?.field?.stacked
                );
            },

            field() {
                return this.filter?.field;
            },

            filterWrapperClasses() {
                return {
                    [this.filterWidthClass]: true,
                    "flex flex-col py-2 space-y-2 md:flex-row md:py-0 md:space-y-0":
                        !this.shouldStack,
                    "border-gray-400 border-b": !this.shouldStack,
                };
            },

            filterWidthClass() {
                return gridSpan(this.width, this.defaultWidth);
            },

            labelWrapperClasses() {
                // prettier-ignore
                return {
                    'flex': true,
                    'items-center bg-gray-200 px-3 dark:bg-gray-700 md:w-1/4 md:py-2': ! this.shouldStack
                };
            },

            controlWrapperClasses() {
                // prettier-ignore
                return {
                    'w-full relative': true,
                    'px-3 md:w-3/4 md:py-3': ! this.shouldStack
                };
            },
        },
    };
</script>
