<template>
    <div
        v-if="panel.fields.length > 0"
        v-show="visibleFieldsCount > 0"
        class="flex flex-col"
        :class="panelWrapperClass"
    >
        <div class="flex items-center">
            <heading
                v-if="panelCount > 1"
                :level="2"
                :dusk="`${dusk}-heading`"
            >
                {{ panel.name != pageTitle ? panel.name : "General" }}
            </heading>

            <help-text-tooltip
                v-if="panel.helpText"
                class="ml-auto"
                :text="panel.helpText"
            />
        </div>

        <card class="flex flex-col">
            <field-grid :stacked="panel.stacked">
                <component
                    v-for="(field, index) in panel.fields"
                    :index="index"
                    :key="index"
                    :is="`form-${field.component}`"
                    :errors="validationErrors"
                    :resource-id="resourceId"
                    :resource-name="resourceName"
                    :related-resource-name="relatedResourceName"
                    :related-resource-id="relatedResourceId"
                    :field="field"
                    :via-resource="viaResource"
                    :via-resource-id="viaResourceId"
                    :via-relationship="viaRelationship"
                    :shown-via-new-relation-modal="shownViaNewRelationModal"
                    :form-unique-id="formUniqueId"
                    :mode="mode"
                    @field-shown="handleFieldShown"
                    @field-hidden="handleFieldHidden"
                    @field-changed="(e) => $emit('field-changed', e)"
                    @file-deleted="handleFileDeleted"
                    @file-upload-started="$emit('file-upload-started')"
                    @file-upload-finished="$emit('file-upload-finished')"
                    :show-help-text="showHelpText"
                />
            </field-grid>
        </card>
    </div>
</template>

<script>
    import each from "lodash/each";
    import filter from "lodash/filter";
    import { gridSpan } from "@nova/utils";

    export default {
        emits: [
            "field-changed",
            "field-hidden",
            "field-shown",
            "file-deleted",
            "file-upload-finished",
            "file-upload-started",
            "update-last-retrieved-at-timestamp",
        ],

        inject: ["pageTitle"],

        props: {
            panel: {
                type: Object,
                required: true,
            },
            panelCount: {
                type: Number,
                required: true,
            },
            name: {
                type: String,
                default: "Panel",
            },
            mode: {
                type: String,
                default: "form",
                validator: (v) =>
                    [
                        "form",
                        "modal",
                        "action-modal",
                        "action-fullscreen",
                    ].includes(v),
            },
            shownViaNewRelationModal: {
                type: Boolean,
                default: false,
            },
            showHelpText: {
                type: Boolean,
                default: false,
            },
            dusk: {
                type: String,
            },
            formUniqueId: {
                type: String,
            },
            validationErrors: {
                type: Object,
                required: true,
            },
            resourceName: {
                type: String,
                required: true,
            },
            resourceId: {
                type: [Number, String],
            },
            relatedResourceName: {
                type: String,
            },
            relatedResourceId: {
                type: [Number, String],
            },
            viaResource: {
                type: String,
            },
            viaResourceId: {
                type: [Number, String],
            },
            viaRelationship: {
                type: String,
            },
        },

        data: () => ({
            visibleFieldsForPanel: {},
        }),

        created() {
            each(this.panel.fields, (field) => {
                this.visibleFieldsForPanel[field.attribute] = field.visible;
            });
        },

        methods: {
            handleFieldShown(field) {
                this.visibleFieldsForPanel[field] = true;
                this.$emit("field-shown", field);
            },

            handleFieldHidden(field) {
                this.visibleFieldsForPanel[field] = false;
                this.$emit("field-hidden", field);
            },

            handleFileDeleted() {
                this.$emit("update-last-retrieved-at-timestamp");
            },
        },

        computed: {
            visibleFieldsCount() {
                return Object.entries(
                    filter(
                        this.visibleFieldsForPanel,
                        (visible) => visible === true,
                    ),
                ).length;
            },

            panelWrapperClass() {
                return gridSpan(this.panel.width);
            },
        },
    };
</script>
