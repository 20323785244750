<template>
    <loading-view
        :loading="initialLoading"
        :dusk="`${resourceName}-index-component`"
        :data-resource="resourceName"
        :data-relationship="viaRelationship"
        :class="{
            'mx-4': !isRelation,
        }"
    >
        <template v-if="shouldOverrideMeta && resourceInformation">
            <app-head
                :title="resourceInformation.label"
                :section="resourceInformation.group"
            />

            <teleport to="#page-title">
                {{ resourceInformation.label }}
            </teleport>
        </template>

        <div
            v-else
            class="flex items-center justify-between mb-2"
        >
            <heading
                :level="2"
                dusk="index-heading"
                class="flex items-center"
            >
                {{ headingTitle }}
            </heading>

            <div :id="`${resourceName}-panel-actions`" />
        </div>

        <teleport
            :to="
                shouldOverrideMeta
                    ? '#page-actions'
                    : `#${resourceName}-panel-actions`
            "
        >
            <div
                class="flex flex-col-reverse lg:flex-row lg:items-center gap-2 lg:divide-x lg:divide-gray-300"
            >
                <div
                    v-if="availableStandaloneRunActions.length > 0"
                    class="ml-auto"
                >
                    <div class="flex flex-wrap items-center gap-2">
                        <action-buttons
                            @actionExecuted="handleActionExecuted"
                            :resource-name="resourceName"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                            :via-relationship="viaRelationship"
                            :relationship-type="relationshipType"
                            :actions="availableStandaloneRunActions"
                            :selected-resources="
                                selectedResourcesForActionSelector
                            "
                        />
                    </div>
                </div>
                <button-group class="lg:pl-2 first:pl-0">
                    <action-buttons
                        v-if="
                            availableStandaloneLinkActions.length > 0 ||
                            shouldShowCreateButton
                        "
                        @actionExecuted="handleActionExecuted"
                        :resource-name="resourceName"
                        :via-resource="viaResource"
                        :via-resource-id="viaResourceId"
                        :via-relationship="viaRelationship"
                        :relationship-type="relationshipType"
                        :actions="availableStandaloneLinkActions"
                        :selected-resources="selectedResourcesForActionSelector"
                    >
                        <!-- Create Link -->
                        <create-resource-button
                            :authorized-to-create="authorizedToCreate"
                            :authorized-to-relate="authorizedToRelate"
                        />
                    </action-buttons>
                </button-group>
            </div>
        </teleport>

        <template v-if="!shouldBeCollapsed">
            <card class="grid grid-cols-6">
                <div
                    v-show="filterDockLeft"
                    class="pr-4 border-r mr-4"
                >
                    <field-grid
                        data-dock="left"
                        stacked
                    />
                </div>

                <div
                    :class="{
                        'col-span-5': filterDockLeft,
                        'col-span-6': !filterDockLeft,
                    }"
                >
                    <field-grid
                        v-show="filterDockTop"
                        data-dock="top"
                        stacked
                        class="mb-4"
                    />
                    <resource-table-toolbar
                        :action-query-string="actionQueryString"
                        :all-matching-resource-count="allMatchingResourceCount"
                        :available-actions="availableActions"
                        :clear-selected-filters="clearSelectedFilters"
                        :close-delete-modal="closeDeleteModal"
                        :current-page-count="resources.length"
                        :delete-all-matching-resources="
                            deleteAllMatchingResources
                        "
                        :delete-selected-resources="deleteSelectedResources"
                        :filter-dock-changed="filterDockChanged"
                        :filter-changed="filterChanged"
                        :force-delete-all-matching-resources="
                            forceDeleteAllMatchingResources
                        "
                        :force-delete-selected-resources="
                            forceDeleteSelectedResources
                        "
                        :get-resources="getResources"
                        :has-filters="hasFilters"
                        :have-standalone-actions="haveStandaloneActions"
                        :lenses="lenses"
                        :loading="resourceResponse && loading"
                        :per-page-options="perPageOptions"
                        :per-page="perPage"
                        :pivot-actions="pivotActions"
                        :pivot-name="pivotName"
                        :resources="resources"
                        :resource-information="resourceInformation"
                        :resource-name="resourceName"
                        :resource-response="resourceResponse"
                        :restore-all-matching-resources="
                            restoreAllMatchingResources
                        "
                        :restore-selected-resources="restoreSelectedResources"
                        :select-all-matching-checked="
                            selectAllMatchingResources
                        "
                        @deselect="clearResourceSelections"
                        :selected-resources="selectedResources"
                        :selected-resources-for-action-selector="
                            selectedResourcesForActionSelector
                        "
                        :should-show-action-selector="shouldShowActionSelector"
                        :should-show-checkboxes="shouldShowCheckboxes"
                        :should-show-delete-menu="shouldShowDeleteMenu"
                        :soft-deletes="softDeletes"
                        :toggle-select-all-matching="toggleSelectAllMatching"
                        :toggle-select-all="toggleSelectAll"
                        :trashed-changed="trashedChanged"
                        :trashed="trashed"
                        :update-per-page-changed="updatePerPageChanged"
                        :via-resource="viaResource"
                    >
                        <template #search>
                            <index-search-input
                                v-model:keyword="search"
                                @update:keyword="search = $event"
                            />
                        </template>

                        <template #action-selector>
                            <action-selector
                                :resource-name="resourceName"
                                :via-resource="actionQueryString.viaResource"
                                :via-resource-id="
                                    actionQueryString.viaResourceId
                                "
                                :via-relationship="
                                    actionQueryString.viaRelationship
                                "
                                :actions="availableActions"
                                :pivot-actions="pivotActions"
                                :pivot-name="pivotName"
                                :selected-resources="
                                    selectedResourcesForActionSelector
                                "
                                @actionExecuted="getResources"
                            />
                        </template>

                        <template #polling>
                            <v-button
                                v-if="shouldShowPollingToggle"
                                @click="togglePolling"
                                :icon="
                                    currentlyPolling ? 'mdi-sync' : 'mdi-clock'
                                "
                                :icon-class="{
                                    'animate-spin-slow -scale-100':
                                        currentlyPolling,
                                }"
                                padding="tight"
                                :variant="
                                    currentlyPolling ? 'solid' : 'outline'
                                "
                                :severity="currentlyPolling ? 'info' : 'light'"
                                :title="
                                    currentlyPolling
                                        ? __('Stop Polling')
                                        : __('Start Polling')
                                "
                            />
                        </template>
                    </resource-table-toolbar>

                    <loading-view
                        :loading="loading"
                        :variant="!resourceResponse ? 'default' : 'overlay'"
                    >
                        <index-error-dialog
                            v-if="resourceResponseError != null"
                            :resource="resourceInformation"
                            @click="getResources"
                        />
                        <index-empty-dialog
                            v-else-if="!resources.length"
                            :create-button-label="createButtonLabel"
                            :resource-label="resourceInformation?.label"
                            :resource-name="resourceName"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                            :via-relationship="viaRelationship"
                            :relationship-type="relationshipType"
                            :authorized-to-create="authorizedToCreate"
                            :authorized-to-relate="authorizedToRelate"
                        />
                        <template v-else>
                            <resource-table
                                :authorized-to-relate="authorizedToRelate"
                                :resource-name="resourceName"
                                :resources="resources"
                                :resource-information="resourceInformation"
                                :singular-name="singularName"
                                :selected-resources="selectedResources"
                                :selected-resource-ids="selectedResourceIds"
                                :actions-are-available="allActions.length > 0"
                                :should-show-checkboxes="shouldShowCheckboxes"
                                :via-resource="viaResource"
                                :via-resource-id="viaResourceId"
                                :via-relationship="viaRelationship"
                                :relationship-type="relationshipType"
                                :update-selection-status="updateSelectionStatus"
                                :sortable="sortable"
                                @order="orderByField"
                                @reset-order-by="resetOrderBy"
                                @delete="deleteResources"
                                @restore="restoreResources"
                                @actionExecuted="handleActionExecuted"
                                ref="resourceTable"
                                class="mb-2"
                            />

                            <resource-pagination
                                v-if="shouldShowPagination"
                                :pagination-component="paginationComponent"
                                :has-next-page="hasNextPage"
                                :has-previous-page="hasPreviousPage"
                                :load-more="loadMore"
                                :loading-more="loadingMore"
                                :select-page="selectPage"
                                :total-pages="totalPages"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :resource-count-label="resourceCountLabel"
                                :current-resource-count="currentResourceCount"
                                :all-matching-resource-count="
                                    allMatchingResourceCount
                                "
                            />
                        </template>
                    </loading-view>
                </div>
            </card>
        </template>
    </loading-view>
</template>

<script>
    import ResourceListingPage from "@nova/extendables/ResourceListingPage.vue";
    import debounce from "lodash/debounce";
    import filter from "lodash/filter";
    import find from "lodash/find";
    import includes from "lodash/includes";
    import map from "lodash/map";
    import pickBy from "lodash/pickBy";
    import { CancelToken, isCancel } from "axios";
    import { computed } from "vue";
    import { mapActions } from "vuex";
    import { mapProps } from "@nova/utils";

    const filterDockOptions = [
        {
            value: "undock",
            label: "Undock into Dropdown",
            icon: "mdi-dock-window",
        },
        {
            value: "top",
            label: "Dock to Top",
            icon: "mdi-dock-top",
        },
        {
            value: "left",
            label: "Dock to Left",
            icon: "mdi-dock-left",
        },
    ];

    export default {
        extends: ResourceListingPage,
        props: {
            ...mapProps(["disablePagination"]),

            field: Object,
            initialPerPage: Number,
        },

        provide() {
            return {
                resourceHasId: computed(() => this.resourceHasId),
                resourceInformation: computed(() => this.resourceInformation),
                resourceName: computed(() => this.resourceName),
                viaResource: computed(() => this.viaResource),
                viaResourceId: computed(() => this.viaResourceId),
                viaRelationship: computed(() => this.viaRelationship),
                viaManyToMany: computed(() => this.viaManyToMany),
                relationshipType: computed(() => this.relationshipType),
                authorizedToViewAnyResources: computed(
                    () => this.authorizedToViewAnyResources,
                ),
                authorizedToUpdateAnyResources: computed(
                    () => this.authorizedToUpdateAnyResources,
                ),
                authorizedToDeleteAnyResources: computed(
                    () => this.authorizedToDeleteAnyResources,
                ),
                authorizedToRestoreAnyResources: computed(
                    () => this.authorizedToRestoreAnyResources,
                ),
                authorizedToDeleteSelectedResources: computed(
                    () => this.authorizedToDeleteSelectedResources,
                ),
                authorizedToForceDeleteSelectedResources: computed(
                    () => this.authorizedToForceDeleteSelectedResources,
                ),
                authorizedToForceDeleteAnyResources: computed(
                    () => this.authorizedToForceDeleteAnyResources,
                ),
                authorizedToRestoreSelectedResources: computed(
                    () => this.authorizedToRestoreSelectedResources,
                ),
                filterDockTarget: computed(() => this.filterDockTarget),
                filterDockOptions: computed(() => this.filterDockOptions),
                selectedResourcesCount: computed(
                    () => this.selectedResources.length,
                ),
                selectAllChecked: computed(() => this.selectAllChecked),
                selectAllMatchingChecked: computed(
                    () => this.selectAllMatchingChecked,
                ),
                selectAllOrSelectAllMatchingChecked: computed(
                    () => this.selectAllOrSelectAllMatchingChecked,
                ),
                selectAllAndSelectAllMatchingChecked: computed(
                    () => this.selectAllAndSelectAllMatchingChecked,
                ),
                selectAllIndeterminate: computed(
                    () => this.selectAllIndeterminate,
                ),
                selectedFilterDockOption: computed(
                    () => this.selectedFilterDockOption,
                ),
                trashedParameter: computed(() => this.trashedParameter),
                orderByParameter: computed(() => this.orderByParameter),
                orderByDirectionParameter: computed(
                    () => this.orderByDirectionParameter,
                ),
            };
        },

        data: () => ({
            actionCanceller: null,
            actions: [],
            allMatchingResourceCount: 0,
            authorizedToRelate: false,
            canceller: null,
            currentPageLoadMore: null,
            deleteModalOpen: false,
            filterDockOptions,
            initialLoading: true,
            lenses: [],
            loading: true,
            loadingMore: false,
            orderBy: "",
            orderByDirection: "",
            pivotActions: null,
            resourceHasActions: false,
            resourceHasId: true,
            resourceResponse: null,
            resourceResponseError: null,
            resources: [],
            search: "",
            selectAllMatchingResources: false,
            selectedFilterDockOption: filterDockOptions[0],
            selectedResources: [],
            softDeletes: false,
            sortable: true,
            trashed: "",
        }),

        async created() {
            if (Nova.missingResource(this.resourceName)) {
                return Nova.visit("/404");
            }

            const debouncer = debounce(
                (callback) => callback(),
                this.resourceInformation.debounce,
            );

            this.initializeSearchFromQueryString();
            this.initializePerPageFromQueryString();
            this.initializeTrashedFromQueryString();
            this.initializeOrderingFromQueryString();

            await Promise.all([
                this.initializeFilters(this.lens || null).then(() => {
                    this.getResources();
                }),
                !this.isLensView ? this.getAuthorizationToRelate() : null,
                this.getActions(),
                // this.getLenses(),
            ]);

            this.initialLoading = false;

            this.$watch(
                () => {
                    return (
                        this.lens +
                        this.resourceName +
                        this.encodedFilters +
                        this.currentSearch +
                        this.currentPage +
                        this.currentPerPage +
                        this.currentOrderBy +
                        this.currentOrderByDirection +
                        this.currentTrashed
                    );
                },
                () => {
                    if (this.canceller !== null) this.canceller();

                    if (this.currentPage === 1) {
                        this.currentPageLoadMore = null;
                    }

                    this.getResources();
                },
            );

            this.$watch("search", (newValue) => {
                this.search = newValue;
                debouncer(() => this.performSearch());
            });

            // Bind the keydown event listener when the router is visited if this
            // component is not a relation on a Detail page
            if (this.shouldEnableShortcut === true) {
                Nova.addShortcut("c", this.handleKeydown);
                Nova.addShortcut("mod+a", this.toggleSelectAll);
                Nova.addShortcut("mod+shift+a", this.toggleSelectAllMatching);
            }

            Nova.$on("refresh-resources", this.getResources);
            Nova.$on("resources-detached", this.getAuthorizationToRelate);
        },

        beforeUnmount() {
            if (this.canceller !== null) {
                this.canceller();
            }

            if (this.shouldEnableShortcut) {
                Nova.disableShortcut("c");
                Nova.disableShortcut("mod+a");
                Nova.disableShortcut("mod+shift+a");
            }

            Nova.$off("refresh-resources", this.getResources);
            Nova.$off("resources-detached", this.getAuthorizationToRelate);

            if (this.actionCanceller !== null) {
                this.actionCanceller();
            }
        },

        methods: {
            handleResourcesLoaded() {
                this.loading = false;

                if (!this.isLensView && this.resourceResponse.total !== null) {
                    this.allMatchingResourceCount = this.resourceResponse.total;
                } else {
                    this.getAllMatchingResourceCount();
                }

                Nova.$emit(
                    "resources-loaded",
                    this.isLensView
                        ? {
                              resourceName: this.resourceName,
                              lens: this.lens,
                              mode: "lens",
                          }
                        : {
                              resourceName: this.resourceName,
                              mode: this.isRelation ? "related" : "index",
                          },
                );

                this.initializePolling();
            },

            selectAllResources() {
                this.selectedResources = this.resources.slice(0);
            },

            toggleSelectAll(e) {
                if (e) {
                    e.preventDefault();
                }

                if (this.selectAllChecked) {
                    this.clearResourceSelections();
                } else {
                    this.selectAllResources();
                }

                // this.getActions();
            },

            toggleSelectAllMatching(e) {
                if (e) {
                    e.preventDefault();
                }

                if (!this.selectAllMatchingResources) {
                    this.selectAllResources();
                    this.selectAllMatchingResources = true;
                } else {
                    this.selectAllMatchingResources = false;
                }

                // this.getActions();
            },

            updateSelectionStatus(resource) {
                if (!includes(this.selectedResources, resource)) {
                    this.selectedResources.push(resource);
                } else {
                    const index = this.selectedResources.indexOf(resource);
                    if (index > -1) {
                        this.selectedResources.splice(index, 1);
                    }
                }

                this.selectAllMatchingResources = false;

                // this.getActions();
            },

            clearResourceSelections() {
                this.selectAllMatchingResources = false;
                this.selectedResources = [];
            },

            orderByField(field) {
                let direction =
                    this.currentOrderByDirection == "asc" ? "desc" : "asc";

                if (this.currentOrderBy != field.sortableUriKey) {
                    direction = "asc";
                }

                this.updateQueryString({
                    [this.orderByParameter]: field.sortableUriKey,
                    [this.orderByDirectionParameter]: direction,
                });
            },

            resetOrderBy(field) {
                this.updateQueryString({
                    [this.orderByParameter]: field.sortableUriKey,
                    [this.orderByDirectionParameter]: null,
                });
            },

            initializeSearchFromQueryString() {
                this.search = this.currentSearch;
            },

            initializeOrderingFromQueryString() {
                this.orderBy = this.currentOrderBy;
                this.orderByDirection = this.currentOrderByDirection;
            },

            initializeTrashedFromQueryString() {
                this.trashed = this.currentTrashed;
            },

            trashedChanged(trashedStatus) {
                this.trashed = trashedStatus;
                this.updateQueryString({
                    [this.trashedParameter]: this.trashed,
                });
            },

            updatePerPageChanged(perPage) {
                this.perPage = perPage;
                this.perPageChanged();
            },

            selectPage(page) {
                this.updateQueryString({ [this.pageParameter]: page });
            },

            initializePerPageFromQueryString() {
                this.perPage =
                    this.queryStringParams[this.perPageParameter] ||
                    this.initialPerPage ||
                    this.resourceInformation?.perPageOptions[0] ||
                    null;
            },

            closeDeleteModal() {
                this.deleteModalOpen = false;
            },

            performSearch() {
                this.updateQueryString({
                    [this.pageParameter]: 1,
                    [this.searchParameter]: this.search,
                });
            },

            handleActionExecuted() {
                this.fetchPolicies();
                this.getResources();
            },

            ...mapActions(["fetchPolicies"]),

            /**
             * Handle the keydown event
             */
            handleKeydown(e) {
                // `c`
                if (
                    this.authorizedToCreate &&
                    e.target.tagName !== "INPUT" &&
                    e.target.tagName !== "TEXTAREA" &&
                    e.target.contentEditable !== "true"
                ) {
                    Nova.visit(`/resources/${this.resourceName}/new`);
                }
            },

            /**
             * Get the resources based on the current page, search, filters, etc.
             */
            getResources() {
                if (this.shouldBeCollapsed) {
                    this.loading = false;
                    return;
                }

                this.loading = true;
                this.resourceResponseError = null;

                this.$nextTick(() => {
                    this.clearResourceSelections();

                    if (!this.initialLoading) {
                        Nova.$progress.start();
                    }

                    return this.$http
                        .get("/nova-api/" + this.resourceName, {
                            params: this.resourceRequestQueryString,
                            cancelToken: new CancelToken((canceller) => {
                                this.canceller = canceller;
                            }),
                        })
                        .then(({ data }) => {
                            this.resources = [];

                            this.resourceResponse = data;
                            this.resources = data.resources;
                            this.softDeletes = data.softDeletes;
                            this.perPage = data.per_page;
                            this.sortable = data.sortable;

                            this.handleResourcesLoaded();
                        })
                        .catch((e) => {
                            if (isCancel(e)) {
                                return;
                            }

                            this.loading = false;
                            this.resourceResponseError = e;

                            throw e;
                        })
                        .finally(() => {
                            Nova.$progress.done();
                        });
                });
            },

            /**
             * Get the relatable authorization status for the resource.
             */
            getAuthorizationToRelate() {
                if (
                    this.shouldBeCollapsed ||
                    (!this.authorizedToCreate &&
                        this.relationshipType !== "belongsToMany" &&
                        this.relationshipType !== "morphToMany")
                ) {
                    return;
                }

                if (!this.viaResource) {
                    return (this.authorizedToRelate = true);
                }

                return Nova.request()
                    .get(
                        "/nova-api/" +
                            this.resourceName +
                            "/relate-authorization" +
                            "?viaResource=" +
                            this.viaResource +
                            "&viaResourceId=" +
                            this.viaResourceId +
                            "&viaRelationship=" +
                            this.viaRelationship +
                            "&relationshipType=" +
                            this.relationshipType,
                    )
                    .then((response) => {
                        this.authorizedToRelate = response.data.authorized;
                    });
            },

            /**
             * Get the lenses available for the current resource.
             */
            getLenses() {
                this.lenses = [];

                if (this.viaResource) {
                    return;
                }

                return Nova.request()
                    .get("/nova-api/" + this.resourceName + "/lenses")
                    .then((response) => {
                        this.lenses = response.data;
                    });
            },

            /**
             * Get the actions available for the current resource.
             */
            getActions() {
                if (this.actionCanceller !== null) {
                    this.actionCanceller();
                }

                this.actions = [];
                this.pivotActions = null;

                if (this.shouldBeCollapsed) {
                    return;
                }

                return Nova.request()
                    .get(`/nova-api/${this.resourceName}/actions`, {
                        params: {
                            viaResource: this.viaResource,
                            viaResourceId: this.viaResourceId,
                            viaRelationship: this.viaRelationship,
                            relationshipType: this.relationshipType,
                            display: "index",
                            resources: this.selectAllMatchingChecked
                                ? "all"
                                : this.selectedResourceIds,
                            pivots: !this.selectAllMatchingChecked
                                ? this.selectedPivotIds
                                : null,
                        },
                        cancelToken: new CancelToken((canceller) => {
                            this.actionCanceller = canceller;
                        }),
                    })
                    .then((response) => {
                        this.actions = response.data.actions;
                        this.pivotActions = response.data.pivotActions;
                        this.resourceHasActions =
                            response.data.counts.resource > 0;
                    })
                    .catch((e) => {
                        if (isCancel(e)) {
                            return;
                        }

                        throw e;
                    });
            },

            /**
             * Get the count of all of the matching resources.
             */
            getAllMatchingResourceCount() {
                Nova.request()
                    .get("/nova-api/" + this.resourceName + "/count", {
                        params: this.resourceRequestQueryString,
                    })
                    .then((response) => {
                        this.allMatchingResourceCount = response.data.count;
                    });
            },

            /**
             * Load more resources.
             */
            loadMore() {
                this.loadingMore = true;

                if (this.currentPageLoadMore === null) {
                    this.currentPageLoadMore = this.currentPage;
                }

                this.currentPageLoadMore = this.currentPageLoadMore + 1;

                return this.$http
                    .get("/nova-api/" + this.resourceName, {
                        params: {
                            ...this.resourceRequestQueryString,
                            page: this.currentPageLoadMore, // We do this to override whatever page number is in the URL
                        },
                    })
                    .then(({ data }) => {
                        this.resourceResponse = data;
                        this.resources = [...this.resources, ...data.resources];

                        if (data.total !== null) {
                            this.allMatchingResourceCount = data.total;
                        } else {
                            this.getAllMatchingResourceCount();
                        }

                        Nova.$emit("resources-loaded", {
                            resourceName: this.resourceName,
                            mode: this.isRelation ? "related" : "index",
                        });
                    })
                    .finally(() => {
                        this.loadingMore = false;
                    });
            },

            async handleCollapsableChange() {
                this.loading = true;

                this.toggleCollapse();

                if (!this.collapsed) {
                    if (!this.filterHasLoaded) {
                        await this.initializeFilters(null);
                        if (!this.hasFilters) {
                            await this.getResources();
                        }
                    } else {
                        await this.getResources();
                    }

                    await this.getAuthorizationToRelate();
                    // await this.getActions();
                    this.restartPolling();
                } else {
                    this.loading = false;
                }
            },

            filterDockChanged(option) {
                this.selectedFilterDockOption = option;
            },
        },

        computed: {
            hasFilters() {
                return this.$store.getters[`${this.resourceName}/hasFilters`];
            },

            pageParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_page"
                    : this.resourceName + "_page";
            },

            selectAllChecked() {
                return this.selectedResources.length == this.resources.length;
            },

            selectAllIndeterminate() {
                return (
                    Boolean(
                        this.selectAllChecked || this.selectAllMatchingChecked,
                    ) && Boolean(!this.selectAllAndSelectAllMatchingChecked)
                );
            },

            selectAllAndSelectAllMatchingChecked() {
                return this.selectAllChecked && this.selectAllMatchingChecked;
            },

            selectAllOrSelectAllMatchingChecked() {
                return this.selectAllChecked || this.selectAllMatchingChecked;
            },

            selectAllMatchingChecked() {
                return this.selectAllMatchingResources;
            },

            selectedResourceIds() {
                return map(
                    this.selectedResources,
                    (resource) => resource.id.value,
                );
            },

            selectedPivotIds() {
                return map(
                    this.selectedResources,
                    (resource) => resource.id.pivotValue ?? null,
                );
            },

            currentSearch() {
                return this.queryStringParams[this.searchParameter] || "";
            },

            currentOrderBy() {
                return this.queryStringParams[this.orderByParameter] || "";
            },

            currentOrderByDirection() {
                return (
                    this.queryStringParams[this.orderByDirectionParameter] ||
                    null
                );
            },

            currentTrashed() {
                return this.queryStringParams[this.trashedParameter] || "";
            },

            viaManyToMany() {
                return (
                    this.relationshipType == "belongsToMany" ||
                    this.relationshipType == "morphToMany"
                );
            },

            isRelation() {
                return Boolean(this.viaResourceId && this.viaRelationship);
            },

            singularName() {
                if (this.isRelation && this.field) {
                    return this.field.singularLabel;
                }

                if (this.resourceInformation) {
                    return this.resourceInformation.singularLabel;
                }
            },

            hasResources() {
                return Boolean(this.resources.length > 0);
            },

            hasLenses() {
                return Boolean(this.lenses.length > 0);
            },

            shouldShowCards() {
                // Don't show cards if this resource is beings shown via a relations
                return Boolean(this.cards.length > 0 && !this.isRelation);
            },

            shouldShowCheckboxes() {
                return (
                    Boolean(this.hasResources) &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        this.resourceHasActions ||
                            this.authorizedToDeleteAnyResources ||
                            this.canShowDeleteMenu,
                    )
                );
            },

            shouldShowDeleteMenu() {
                return (
                    Boolean(this.selectedResources.length > 0) &&
                    this.canShowDeleteMenu
                );
            },

            authorizedToDeleteSelectedResources() {
                return Boolean(
                    find(
                        this.selectedResources,
                        (resource) => resource.authorizedToDelete,
                    ),
                );
            },

            authorizedToForceDeleteSelectedResources() {
                return Boolean(
                    find(
                        this.selectedResources,
                        (resource) => resource.authorizedToForceDelete,
                    ),
                );
            },

            authorizedToViewAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToView,
                        ),
                    )
                );
            },

            authorizedToUpdateAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToUpdate,
                        ),
                    )
                );
            },

            authorizedToDeleteAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToDelete,
                        ),
                    )
                );
            },

            authorizedToForceDeleteAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToForceDelete,
                        ),
                    )
                );
            },

            authorizedToRestoreSelectedResources() {
                return (
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.selectedResources,
                            (resource) => resource.authorizedToRestore,
                        ),
                    )
                );
            },

            authorizedToRestoreAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToRestore,
                        ),
                    )
                );
            },

            encodedFilters() {
                return this.$store.getters[
                    `${this.resourceName}/currentEncodedFilters`
                ];
            },

            initialEncodedFilters() {
                return this.queryStringParams[this.filterParameter] || "";
            },

            paginationComponent() {
                return `pagination-${Nova.config("pagination") || "links"}`;
            },

            hasNextPage() {
                return Boolean(
                    this.resourceResponse &&
                        this.resourceResponse.next_page_url,
                );
            },

            hasPreviousPage() {
                return Boolean(
                    this.resourceResponse &&
                        this.resourceResponse.prev_page_url,
                );
            },

            totalPages() {
                return Math.ceil(
                    this.allMatchingResourceCount / this.currentPerPage,
                );
            },

            resourceCountLabel() {
                const first = this.perPage * (this.currentPage - 1);

                return (
                    this.resources.length &&
                    `${Nova.formatNumber(first + 1)}-${Nova.formatNumber(
                        first + this.resources.length,
                    )} ${this.__("of")} ${Nova.formatNumber(this.allMatchingResourceCount)}`
                );
            },

            currentPerPage() {
                return this.perPage;
            },

            perPageOptions() {
                if (this.resourceResponse) {
                    return this.resourceResponse.per_page_options;
                }
            },

            createButtonLabel() {
                if (this.resourceInformation)
                    return this.resourceInformation.createButtonLabel;

                return this.__("Create");
            },

            shouldShowActionSelector() {
                return (
                    this.selectedResources.length > 0 ||
                    this.haveStandaloneActions
                );
            },

            isLensView() {
                return (
                    this.lens !== "" &&
                    this.lens != undefined &&
                    this.lens != null
                );
            },

            shouldShowPagination() {
                return (
                    this.disablePagination !== true &&
                    this.resourceResponse &&
                    (this.hasResources || this.hasPreviousPage)
                );
            },

            currentResourceCount() {
                return this.resources.length;
            },

            searchParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_search"
                    : this.resourceName + "_search";
            },

            orderByParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_order"
                    : this.resourceName + "_order";
            },

            orderByDirectionParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_direction"
                    : this.resourceName + "_direction";
            },

            trashedParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_trashed"
                    : this.resourceName + "_trashed";
            },

            perPageParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_per_page"
                    : this.resourceName + "_per_page";
            },

            haveStandaloneActions() {
                return (
                    filter(this.allActions, (a) => a.standalone === true)
                        .length > 0
                );
            },

            availableActions() {
                return this.actions;
            },

            hasPivotActions() {
                return (
                    this.pivotActions && this.pivotActions.actions.length > 0
                );
            },

            pivotName() {
                return this.pivotActions ? this.pivotActions.name : "";
            },

            actionsAreAvailable() {
                return this.allActions.length > 0;
            },

            allActions() {
                return this.hasPivotActions
                    ? this.actions.concat(this.pivotActions.actions)
                    : this.actions;
            },

            availableStandaloneActions() {
                return this.allActions.filter((a) => a.standalone === true);
            },

            availableStandaloneRunActions() {
                return this.availableStandaloneActions.filter((a) => !a.link);
            },

            availableStandaloneLinkActions() {
                return this.availableStandaloneActions.filter((a) => a.link);
            },

            selectedResourcesForActionSelector() {
                return this.selectAllMatchingChecked
                    ? "all"
                    : this.selectedResources;
            },

            actionQueryString() {
                return {
                    currentSearch: this.currentSearch,
                    encodedFilters: this.encodedFilters,
                    currentTrashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },

            shouldBeCollapsed() {
                return this.collapsed && this.viaRelationship != null;
            },

            collapsedByDefault() {
                return this.field?.collapsedByDefault ?? false;
            },

            cardsEndpoint() {
                return `/nova-api/${this.resourceName}/cards`;
            },

            resourceRequestQueryString() {
                return pickBy(
                    {
                        search: this.currentSearch,
                        filters: this.encodedFilters,
                        orderBy: this.currentOrderBy,
                        orderByDirection: this.currentOrderByDirection,
                        perPage: this.currentPerPage,
                        trashed: this.currentTrashed,
                        page: this.currentPage,
                        viaResource: this.viaResource,
                        viaResourceId: this.viaResourceId,
                        viaRelationship: this.viaRelationship,
                        viaResourceRelationship: this.viaResourceRelationship,
                        relationshipType: this.relationshipType,
                        morphToType: this.field?.morphToType,
                        morphToId: this.field?.morphToId,
                    },
                    (v) => v,
                );
            },

            canShowDeleteMenu() {
                return Boolean(
                    this.authorizedToDeleteSelectedResources ||
                        this.authorizedToForceDeleteSelectedResources ||
                        this.authorizedToRestoreSelectedResources ||
                        this.selectAllMatchingChecked,
                );
            },

            headingTitle() {
                if (this.initialLoading) {
                    return "&nbsp;";
                } else {
                    if (this.isRelation && this.field) {
                        return this.field.name;
                    } else {
                        if (this.resourceResponse !== null) {
                            return this.resourceResponse.label;
                        } else {
                            return this.resourceInformation.label;
                        }
                    }
                }
            },

            localStorageKey() {
                let name = this.resourceName;

                if (this.viaRelationship) {
                    name = `${name}.${this.viaRelationship}`;
                }

                return `nova.resources.${name}.collapsed`;
            },

            filterDockTarget() {
                return `[data-resource=${this.resourceName}] [data-dock=${this.selectedFilterDockOption.value}]`;
            },

            filterDockTop() {
                return this.selectedFilterDockOption.value === "top";
            },

            filterDockLeft() {
                return this.selectedFilterDockOption.value === "left";
            },

            shouldShowCreateButton() {
                return (
                    this.authorizedToRelate &&
                    (this.authorizedToCreate ||
                        this.relationshipType === "belongsToMany" ||
                        this.relationshipType === "morphToMany")
                );
            },
        },
    };
</script>
