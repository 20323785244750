<template>
    <panel-item
        :index="index"
        :field="field"
    >
        <template #field-value>
            {{ formattedDate }}
        </template>
    </panel-item>
</template>

<script>
    import dayjs from "dayjs";

    export default {
        props: ["index", "field"],
        computed: {
            formattedDate() {
                if (this.field.usesCustomizedDisplay) {
                    return this.field.displayedAs;
                }

                return dayjs(this.field.value).format(
                    App.config("dayjs.formats.date"),
                );
            },
        },
    };
</script>
