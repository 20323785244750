<script>
    /**
     * @todo Dependent Form Fields are currently stubbed to make the
     *       rollout of Nova features easier, future compatible,
     *       and provides a dropin location when we're ready.
     */
    import FormField from "./FormField.vue";
    import get from "lodash/get";
    import { mapProps } from "@nova/utils";

    export default {
        extends: FormField,

        props: {
            ...mapProps([
                "shownViaNewRelationModal",
                "viaResource",
                "viaResourceId",
                "viaRelationship",
                "resourceName",
                "resourceId",
                "relatedResourceName",
                "relatedResourceId",
            ]),

            syncEndpoint: { type: String, required: false },
        },

        data: () => ({
            syncedField: null,
        }),

        computed: {
            currentField() {
                return this.syncedField || this.field;
            },

            currentlyIsVisible() {
                return this.currentField.visible;
            },

            currentlyIsReadonly() {
                if (this.syncedField !== null) {
                    return Boolean(
                        this.syncedField.readonly ||
                            get(this.syncedField, "extraAttributes.readonly"),
                    );
                }

                return Boolean(
                    this.field.readonly ||
                        get(this.field, "extraAttributes.readonly"),
                );
            },
        },
    };
</script>
