import registerComponents from "../utils/registerComponents.js";
import {
    Froala,
    FroalaView,
} from "@node_modules/vue-froala-wysiwyg/src/vue-froala";

export default {
    install(app, options) {
        registerComponents(
            app,
            import.meta.glob("../components/**/*.vue", { eager: true }),
            options,
        );

        app.component("FroalaEditor", Froala);
        app.component("FroalaView", FroalaView);
    },
};
