<template>
    <div class="relative">
        <div ref="input">
            <slot name="input">
                <div
                    :class="{
                        '!border-gray-600 ring-0': show,
                        'form-control-error': hasError,
                    }"
                    class="form-control"
                    :tabindex="tabindex"
                    :aria-expanded="show === true ? 'true' : 'false'"
                    :dusk="`${dusk}-selected`"
                    @click="open"
                    @keydown.space.prevent="open"
                    @keydown.down.prevent="open"
                    @keydown.up.prevent="open"
                    :data-disabled="disabled"
                >
                    <icon
                        v-if="
                            searchable && shouldShowDropdownArrow && !disabled
                        "
                        name="mdi-magnify"
                        class="pointer-events-none form-select-arrow"
                    />
                    <slot>
                        <div
                            v-if="multiple && value?.length > 0"
                            class="flex flex-wrap gap-1"
                        >
                            <div
                                v-for="option in selectedOptions"
                                :key="option.value"
                                class="bg-blue-500 text-white rounded-full px-2 text-xs flex items-center gap-1"
                            >
                                <div class="py-1">
                                    <span
                                        v-if="asHtml"
                                        v-html="getDisplayByValue(option)"
                                    />
                                    <span
                                        v-else
                                        v-text="getDisplayByValue(option)"
                                    />
                                </div>
                                <button
                                    type="button"
                                    v-if="!disabled"
                                    @click="choose(option)"
                                    tabindex="-1"
                                    class="text-blue-700 hover:text-white cursor-pointer"
                                >
                                    <icon
                                        name="heroicon-c-x-mark"
                                        class="w-4 h-4"
                                    />
                                </button>
                            </div>
                        </div>
                        <div v-else-if="selectedOption">
                            <span
                                v-if="asHtml"
                                v-html="getDisplayByValue(selectedOption)"
                            />
                            <span
                                v-else
                                v-text="getDisplayByValue(selectedOption)"
                            />
                        </div>
                        <div
                            v-else-if="!disabled"
                            class="text-gray-400 dark:text-gray-400"
                        >
                            {{ placeholder }}
                        </div>
                    </slot>
                </div>
            </slot>
            <button
                type="button"
                @click="clear"
                v-if="!shouldShowDropdownArrow && !disabled"
                tabindex="-1"
                class="absolute block right-1 top-1.5"
                :dusk="`${dusk}-clear-button`"
            >
                <icon name="heroicon-m-x-mark" />
            </button>
        </div>
        <teleport to="body">
            <div
                v-if="show"
                ref="dropdown"
                class="rounded-lg px-0 bg-white dark:bg-gray-900 shadow border border-gray-200 dark:border-gray-700 absolute top-0 left-0 my-1 overflow-hidden"
                :style="{ width: inputWidth + 'px', zIndex: 2000 }"
                :dusk="`${dusk}-dropdown`"
            >
                <!-- Search Input -->
                <input
                    v-show="searchable"
                    v-model="searchValue"
                    ref="search"
                    @keydown.enter.prevent="chooseSelected"
                    @keydown.down.prevent="move(1)"
                    @keydown.up.prevent="move(-1)"
                    class="h-10 outline-none w-full px-3 text-sm leading-normal bg-white dark:bg-gray-700 rounded-t border-b border-gray-200 dark:border-gray-800"
                    tabindex="-1"
                    type="search"
                    :placeholder="__('Search')"
                    spellcheck="false"
                />

                <!-- Search Results -->
                <div
                    ref="container"
                    class="relative overflow-y-scroll text-sm max-h-80"
                    tabindex="-1"
                    :dusk="`${dusk}-results`"
                >
                    <template
                        v-for="(options, group) in groupedFilteredOptions"
                    >
                        <div
                            v-if="group"
                            :key="group"
                            class="px-3 py-1.5 bg-gray-300"
                        >
                            {{ group }}
                        </div>
                        <div
                            v-for="(option, index) in options"
                            :dusk="`${dusk}-result-${option.index}`"
                            :key="getTrackedByKey(option)"
                            :ref="
                                option.index === searchOptionIndex
                                    ? 'selected'
                                    : 'unselected'
                            "
                            @click.stop="choose(option)"
                            class="flex items-center px-3 py-1.5 cursor-pointer"
                            :class="{
                                'border-t border-gray-100 dark:border-gray-700':
                                    index !== 0,
                                [`search-input-item-${option.index}`]: true,
                                'hover:bg-gray-100 dark:hover:bg-gray-800':
                                    !isSelected(option),
                                'bg-blue-500 text-white':
                                    isSelected(option) &&
                                    option.index !== searchOptionIndex,
                                'bg-blue-200':
                                    option.index === searchOptionIndex,
                            }"
                        >
                            <slot
                                name="option"
                                :option="option"
                                :selected="isSelected(option)"
                            >
                                <span
                                    v-if="asHtml"
                                    v-html="getDisplayByValue(option)"
                                />
                                <div
                                    v-else
                                    class="flex items-center"
                                >
                                    <div
                                        v-if="option.avatar"
                                        class="flex-none mr-3"
                                    >
                                        <img
                                            :src="option.avatar"
                                            class="w-8 h-8 rounded-full block"
                                        />
                                    </div>

                                    <div class="flex-auto">
                                        <div
                                            class="text-sm"
                                            :class="{
                                                'text-white dark:text-gray-900':
                                                    isSelected(option),
                                            }"
                                        >
                                            {{ getDisplayByValue(option) }}
                                        </div>

                                        <div
                                            v-if="withSubtitles"
                                            class="text-xs text-gray-600"
                                            :class="{
                                                'text-white dark:text-gray-700':
                                                    isSelected(option),
                                            }"
                                        >
                                            <span v-if="option.subtitle">{{
                                                option.subtitle
                                            }}</span>
                                            <span v-else>{{
                                                __(
                                                    "No additional information...",
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </slot>
                            <div
                                v-if="option.index === searchOptionIndex"
                                class="ml-auto"
                            >
                                <div class="text-xxs uppercase">
                                    <span
                                        v-if="multiple && !isSelected(option)"
                                    >
                                        Add
                                    </span>
                                    <span v-else-if="multiple">Remove</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div
                        v-if="limited"
                        :dusk="`${dusk}-limit`"
                        class="flex items-center px-3 py-1.5 cursor-pointer"
                        :class="{
                            'border-t border-gray-100 dark:border-gray-700': true,
                            'text-gray-500 italic pl-8': true,
                        }"
                    >
                        {{ limitedText }}
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";
    import get from "lodash/get";
    import groupBy from "lodash/groupBy";
    import { createPopper } from "@popperjs/core";

    export default {
        emits: ["input", "open", "close", "clear", "select"],
        props: {
            dusk: {
                type: String,
                default: "select",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {},
            options: {
                type: Array,
                required: true,
            },
            displayBy: {
                type: String,
                default: "label",
            },
            trackBy: {
                type: String,
                default: "value",
            },
            groupBy: {
                type: String,
                default: "group",
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            debounce: {
                type: Number,
                default: 500,
            },
            clearable: {
                type: Boolean,
                default: true,
            },
            searchable: {
                type: Boolean,
                default: false,
            },
            limit: {
                type: Number,
                default: 300,
            },
            limitText: {
                type: Function,
                default: (count) => `...and ${count} more`,
            },
            asHtml: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            withSubtitles: {
                type: Boolean,
                default: false,
            },
            placeholder: String,
        },

        data: () => ({
            debouncer: null,
            show: false,
            searchValue: "",
            searchOptionIndex: null,
            popper: null,
            inputWidth: null,
        }),

        watch: {
            searchValue(search) {
                this.searchOptionIndex = 0;
                if (this.$refs.container) {
                    this.$refs.container.scrollTop = 0;
                } else {
                    this.$nextTick(() => {
                        this.$refs.container.scrollTop = 0;
                    });
                }

                this.debouncer(() => {
                    this.$emit("input", search);
                });
            },

            show(show) {
                if (show) {
                    const selected = this.firstSelectedOptionIndex;

                    if (selected !== -1) {
                        this.searchOptionIndex = selected;
                    }

                    this.inputWidth = this.$refs.input.offsetWidth;

                    App.$emit("disable-focus-trap");

                    this.$nextTick(() => {
                        this.popper = createPopper(
                            this.$refs.input,
                            this.$refs.dropdown,
                            {
                                placement: "bottom-start",
                                onFirstUpdate: () => {
                                    this.$refs.container.scrollTop = 0;
                                    this.updateScrollPosition();
                                    this.$refs.search.focus();
                                },
                            },
                        );
                    });
                } else {
                    this.$refs.search.blur();

                    if (this.popper) {
                        this.popper.destroy();
                    }

                    App.$emit("enable-focus-trap");
                }
            },
        },

        created() {
            this.debouncer = debounce((callback) => callback(), this.debounce);
        },

        mounted() {
            document.addEventListener("keydown", this.handleEscape);
            document.addEventListener("mousedown", this.handleClickOutside);
        },

        beforeUnmount() {
            document.removeEventListener("keydown", this.handleEscape);
            document.removeEventListener("mousedown", this.handleClickOutside);
        },

        methods: {
            handleEscape(e) {
                // 'tab' or 'escape'
                if (this.show && (e.keyCode == 9 || e.keyCode == 27)) {
                    setTimeout(() => this.close(), 50);
                }
            },

            handleClickOutside(e) {
                if (!this.show) {
                    return;
                }

                if (this.$el.contains(e.target)) {
                    return;
                }

                if (this.$refs.dropdown?.contains(e.target)) {
                    return;
                }

                this.close();
            },

            getDisplayByValue(option) {
                return get(option, this.displayBy);
            },

            getTrackedByKey(option) {
                return get(option, this.trackBy);
            },

            open() {
                if (this.disabled) {
                    return;
                }

                this.show = true;
                this.searchValue = "";
                this.$emit("open");
            },

            close() {
                this.show = false;
                this.$emit("close");
            },

            clear() {
                this.searchOptionIndex = null;
                this.$emit("clear", null);
            },

            move(offset) {
                const newIndex = this.searchOptionIndex + offset;

                if (newIndex >= 0 && newIndex < this.filteredOptions.length) {
                    this.searchOptionIndex = newIndex;
                    this.updateScrollPosition();
                }
            },

            updateScrollPosition() {
                this.$nextTick(() => {
                    if (this.$refs.selected && this.$refs.selected[0]) {
                        if (
                            this.$refs.selected[0].offsetTop >
                            this.$refs.container.scrollTop +
                                this.$refs.container.clientHeight -
                                this.$refs.selected[0].clientHeight
                        ) {
                            this.$refs.container.scrollTop =
                                this.$refs.selected[0].offsetTop +
                                this.$refs.selected[0].clientHeight -
                                this.$refs.container.clientHeight;
                        }

                        if (
                            this.$refs.selected[0].offsetTop <
                            this.$refs.container.scrollTop
                        ) {
                            this.$refs.container.scrollTop =
                                this.$refs.selected[0].offsetTop;
                        }
                    }
                });
            },

            chooseSelected(event) {
                if (event.isComposing || event.keyCode === 229) {
                    return;
                }

                if (this.options[this.searchOptionIndex] !== undefined) {
                    this.$emit(
                        "select",
                        this.filteredOptions[this.searchOptionIndex],
                    );

                    if (!this.multiple) {
                        this.$nextTick(() => this.close());
                    }
                }
            },

            choose(option) {
                this.searchOptionIndex = null;

                this.$emit("select", option);

                if (!this.multiple) {
                    this.$nextTick(() => this.close());
                }
            },

            isSelected(option) {
                if (!this.value) {
                    return false;
                }

                if (Array.isArray(this.value)) {
                    return (
                        this.value.includes(String(option[this.trackBy])) ||
                        this.value.includes(parseFloat(option[this.trackBy]))
                    );
                }

                return option[this.trackBy] === this.value;
            },
        },

        computed: {
            tabindex() {
                if (this.disabled) {
                    return null;
                }

                return this.show ? -1 : 0;
            },

            filteredOptions() {
                return this.options.filter((option) => {
                    return (
                        option[this.displayBy]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchValue.toLowerCase()) > -1
                    );
                });
            },

            limitedOptions() {
                return this.filteredOptions.slice(0, this.limit);
            },

            remainingCount() {
                return Math.max(this.filteredOptions.length - this.limit, 0);
            },

            limited() {
                return this.remainingCount > 0;
            },

            limitedText() {
                return this.limitText(this.remainingCount.toLocaleString());
            },

            groupedFilteredOptions() {
                return groupBy(
                    this.limitedOptions.map((option, index) => {
                        option.index = index;
                        return option;
                    }),
                    (option) => option[this.groupBy] || "",
                );
            },

            shouldShowDropdownArrow() {
                return (
                    this.value == "" || this.value == null || !this.clearable
                );
            },

            selectedOption() {
                return this.options[this.firstSelectedOptionIndex];
            },

            selectedOptions() {
                return this.options.filter((option) => {
                    return this.isSelected(option);
                });
            },

            firstSelectedOptionIndex() {
                for (const [index, option] of Object.entries(this.options)) {
                    if (this.isSelected(option)) {
                        return index;
                    }
                }

                return -1;
            },
        },
    };
</script>
