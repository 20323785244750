<template>
    <span
        class="inline-flex items-center whitespace-nowrap rounded-full uppercase font-bold"
        :class="{
            'min-h-6 text-xs px-2': !circle,
            'h-4 text-xxs px-1.5 py-px': circle,
            [extraClasses]: true,
        }"
    >
        <slot name="icon" />
        <slot>
            {{ label }}
        </slot>
    </span>
</template>

<script>
    export default {
        props: {
            label: {
                type: [Boolean, String, Number],
                required: false,
            },

            circle: {
                type: Boolean,
                default: false,
            },

            extraClasses: {
                type: [Array, String],
                required: false,
                default: "",
            },
        },
    };
</script>
