<template>
    <help-text-tooltip
        v-if="range"
        :title="title"
        :text="rangeText"
        icon="icomoon-calendar"
    />
</template>

<script>
    import { formatNumber } from "@nova/utils";
    import dayjs from "@/utils/dayjs";

    export default {
        props: {
            /**
             * @property {Object=} range
             * @property {Number}  range.length
             * @property {String}  range.resolution ("Minute"|"Hour"|"Day"|"Week"|"Month")
             * @property {String}  range.unit ("minutes"|"hours"|"days"|"weeks"|"months")
             * @property {String}  range.start (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.end (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.timezone (e.g. "America/Chicago")
             */
            range: Object,
            title: {
                type: String,
                default: "Range",
            },
        },
        computed: {
            rangeText() {
                const interval = formatNumber(this.range.length, {
                    output: "time",
                    unit: this.range.unit,
                });

                const start = this.formatDate(this.range.start);
                const end = this.formatDate(this.range.end);

                return `
                    <table class="mb-0">
                        <tbody>
                            <tr>
                                <th class="p-0 pr-1 text-left">Length</th>
                                <td class="p-0">${interval}</td>
                            </tr>
                            <tr>
                                <th class="p-0 pr-1 text-left">From</th>
                                <td class="p-0 whitespace-nowrap">${start}</td>
                            </tr>
                            <tr>
                                <th class="p-0 pr-1 text-left">To</th>
                                <td class="p-0 whitespace-nowrap">${end}</td>
                            </tr>
                        </tbody>
                    </table>`;
            },
        },
        methods: {
            formatDate(date) {
                return dayjs(date).format("dddd, MMMM Do, YYYY, h:mm:ss A (z)");
            },
        },
    };
</script>
