<template>
    <div
        v-if="
            shouldShowCheckboxes ||
            shouldShowDeleteMenu ||
            softDeletes ||
            !viaResource ||
            hasFilters ||
            haveStandaloneActions ||
            resourceInformation?.searchable
        "
        class="flex flex-col gap-2 bg-gray-700 text-white px-4 py-2 rounded-t-md"
    >
        <div class="flex items-center gap-4">
            <!-- Select All Dropdown -->
            <select-all-dropdown
                v-if="shouldShowCheckboxes"
                :all-matching-resource-count="allMatchingResourceCount"
                :current-page-count="currentPageCount"
                @toggle-select-all="toggleSelectAll"
                @toggle-select-all-matching="toggleSelectAllMatching"
                @deselect="$emit('deselect')"
            />

            <!-- Search -->
            <div
                v-if="resourceInformation && resourceInformation.searchable"
                class="hidden md:block w-full flex-1 lg:flex-none md:w-1/3 2xl:w-1/4"
            >
                <slot name="search" />
            </div>

            <div class="block md:hidden lg:block flex-1" />

            <!-- Action Selector -->
            <div
                v-if="shouldShowActionSelector"
                class="hidden lg:block lg:w-1/4 xl:w-1/6"
            >
                <slot name="action-selector" />
            </div>

            <!-- Resource Polling -->
            <slot name="polling" />

            <!-- @todo Lenses -->

            <!-- Filter Menu -->
            <filter-menu
                v-if="filters.length > 0 || softDeletes || !viaResource"
                :active-filter-count="activeFilterCount"
                :filters-are-applied="filtersAreApplied"
                :filters="filters"
                :per-page-options="filterPerPageOptions"
                :per-page="perPage"
                :soft-deletes="softDeletes"
                :trashed="trashed"
                @clear-selected-filters="clearSelectedFilters(lens || null)"
                @dock-changed="filterDockChanged"
                @filter-changed="filterChanged"
                @per-page-changed="updatePerPageChanged"
                @trashed-changed="trashedChanged"
            />

            <!-- Delete Menu -->
            <delete-menu
                v-if="shouldShowDeleteMenu"
                class="flex"
                dusk="delete-menu"
                :soft-deletes="softDeletes"
                :resources="resources"
                :selected-resources="selectedResources"
                :all-matching-resource-count="allMatchingResourceCount"
                :all-matching-selected="selectAllMatchingChecked"
                @deleteSelected="deleteSelectedResources"
                @deleteAllMatching="deleteAllMatchingResources"
                @forceDeleteSelected="forceDeleteSelectedResources"
                @forceDeleteAllMatching="forceDeleteAllMatchingResources"
                @restoreSelected="restoreSelectedResources"
                @restoreAllMatching="restoreAllMatchingResources"
                @close="closeDeleteModal"
            />
        </div>

        <!-- Mobile Search -->
        <div
            v-if="resourceInformation && resourceInformation.searchable"
            class="block md:hidden -ml-2"
        >
            <slot name="search" />
        </div>

        <!-- Mobile Action Selector -->
        <div
            v-if="shouldShowActionSelector"
            class="block lg:hidden -ml-2"
        >
            <slot name="action-selector" />
        </div>
    </div>
</template>

<script>
    export default {
        emits: ["deselect"],

        props: [
            "allMatchingResourceCount",
            "clearSelectedFilters",
            "closeDeleteModal",
            "deleteAllMatchingResources",
            "deleteSelectedResources",
            "filterChanged",
            "filterDockChanged",
            "forceDeleteAllMatchingResources",
            "forceDeleteSelectedResources",
            "getResources",
            "hasFilters",
            "haveStandaloneActions",
            "lenses",
            "lens",
            "isLensView",
            "perPage",
            "perPageOptions",
            "pivotName",
            "resources",
            "resourceInformation",
            "resourceName",
            "resourceResponse",
            "currentPageCount",
            "restoreAllMatchingResources",
            "restoreSelectedResources",
            "selectAllChecked",
            "selectAllMatchingChecked",
            "selectedResources",
            "shouldShowActionSelector",
            "shouldShowCheckboxes",
            "shouldShowDeleteMenu",
            "softDeletes",
            "toggleSelectAll",
            "toggleSelectAllMatching",
            "trashed",
            "trashedChanged",
            "updatePerPageChanged",
            "viaResource",
        ],

        computed: {
            filters() {
                return this.$store.getters[`${this.resourceName}/filters`];
            },

            filtersAreApplied() {
                return this.$store.getters[
                    `${this.resourceName}/filtersAreApplied`
                ];
            },

            activeFilterCount() {
                return this.$store.getters[
                    `${this.resourceName}/activeFilterCount`
                ];
            },

            filterPerPageOptions() {
                if (this.resourceInformation) {
                    return (
                        this.perPageOptions ||
                        this.resourceInformation.perPageOptions
                    );
                }
            },
        },
    };
</script>
