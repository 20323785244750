<template>
    <div :class="fieldWrapperClasses">
        <div :class="labelWrapperClasses">
            <slot name="label">
                <form-label
                    :label="field.name"
                    :sublabel="field.sublabel"
                    :required="field.required"
                />
            </slot>
            <div class="ml-auto relative flex items-center gap-1">
                <help-text-tooltip
                    v-if="field.helpText"
                    :text="field.helpText"
                />
                <copy-button
                    v-if="field.value && field.copyable"
                    @click.prevent.stop="copy"
                />
            </div>
        </div>

        <div :class="controlWrapperClasses">
            <slot name="field" />

            <help-text
                class="help-text-error"
                v-if="error"
            >
                {{ error }}
            </help-text>
        </div>
    </div>
</template>

<script>
    import { gridSpan } from "@nova/utils";
    import { useClipboard } from "@vueuse/core";

    export default {
        props: {
            field: { type: Object, required: true },
            error: { type: String },
        },

        methods: {
            copy() {
                useClipboard({
                    source: this.field.value,
                    legacy: true,
                }).copy(this.field.value);
            },
        },

        computed: {
            fieldWrapperClasses() {
                return {
                    [this.fieldWidthClass]: true,
                    "flex flex-col py-2 space-y-2 md:flex-row md:py-0 md:space-y-0":
                        !this.stacked,
                    "border-gray-400 border-b dark:border-gray-700":
                        !this.stacked,
                    "@md/modal:border-b-0": !this.stacked,
                };
            },

            fieldWidthClass() {
                return gridSpan(this.field.width);
            },

            labelWrapperClasses() {
                // prettier-ignore
                return {
                    "flex": true,
                    "items-center bg-gray-200 px-3 dark:bg-gray-800 md:w-1/4 md:py-2": ! this.stacked,
                    "@md/modal:bg-white": ! this.stacked,
                };
            },

            controlWrapperClasses() {
                return {
                    "w-full relative": true,
                    "px-3 md:w-3/4 md:py-3": !this.stacked,
                };
            },

            stacked() {
                return this.field.stacked === false ? false : true;
            },
        },
    };
</script>
