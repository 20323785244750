<template>
    <default-field
        :field="field"
        :error="error"
    >
        <template #field>
            <toggle
                v-model="value"
                @change="updateModelValue()"
                :id="field.uniqueKey"
                :name="field.validationKey"
                :dusk="field.attribute"
                :disabled="currentlyIsReadonly"
                class="mt-2"
                :class="errorClasses"
                :error="error"
            />
        </template>
    </default-field>
</template>

<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";

    export default {
        extends: DependentFormField,
        methods: {
            getDefaultValue() {
                return false;
            },
        },

        computed: {
            checked() {
                return Boolean(this.value);
            },

            trueValue() {
                return +this.checked;
            },

            fillValue() {
                return this.value ? 1 : 0;
            },
        },
    };
</script>
