<template>
    <render />
</template>
<script setup>
    import { h } from "vue";

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
    });

    const render = () => {
        const raw = window.App.icon(props.name) || false;

        if (!raw) {
            return h("div", {
                class: "text-red-500",
                innerHTML: `Missing icon "${props.name}".`,
            });
        }

        const element = new DOMParser().parseFromString(
            raw,
            "image/svg+xml",
        ).documentElement;

        const attributes = {};
        for (const attribute of element.attributes) {
            attributes[attribute.name] = attribute.value;
        }

        return h("svg", {
            ...attributes,
            innerHTML: element.innerHTML,
        });
    };
</script>
