<template>
    <filter-container :filter="filter">
        <template #filter>
            <select-control
                :dusk="currentField.attribute"
                v-model:selected="value"
                @change="value = $event"
                :options="options"
            >
                <option
                    value=""
                    selected
                >
                    {{ placeholder }}
                </option>
            </select-control>
        </template>
    </filter-container>
</template>

<script>
    import SelectField from "./SelectField.vue";

    export default {
        extends: SelectField,
        data() {
            return {
                options: [
                    { value: 1, label: this.__("Yes") },
                    { value: 0, label: this.__("No") },
                ],
            };
        },
        computed: {
            isSearchable() {
                return this.currentField.searchable;
            },

            placeholder() {
                return this.currentField.placeholder || this.__("ANY");
            },
        },
    };
</script>
