<template>
    <default-field
        :field="currentField"
        :errors="errors"
    >
        <template #field>
            <input
                v-bind="extraAttributes"
                type="color"
                class="form-control"
                @input="handleChange"
                :value="value"
                :id="currentField.uniqueKey"
                :dusk="field.attribute"
                :disabled="currentlyIsReadonly"
            />

            <datalist
                v-if="suggestions.length > 0"
                :id="suggestionsId"
            >
                <option
                    :key="suggestion"
                    v-for="suggestion in suggestions"
                    :value="suggestion"
                />
            </datalist>
        </template>
    </default-field>
</template>

<script>
    import InputField from "./InputField.vue";

    export default {
        extends: InputField,
    };
</script>
