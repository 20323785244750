<template>
    <div :class="`text-${field.textAlign}`">
        <p
            v-if="fieldValue"
            class="inline-flex items-center gap-1"
            :class="alignClass"
            v-bind="$attrs"
        >
            <slot
                name="value"
                :item="this"
            >
                <span v-if="!field.asHtml">
                    <slot
                        name="field-value"
                        :item="this"
                    >
                        {{ fieldValue }}
                    </slot>
                </span>
                <div
                    v-else
                    @click.stop
                    v-html="fieldValue"
                />
            </slot>
            <copy-button
                v-if="fieldValue && field.copyable"
                @click.prevent.stop="copy"
            />
        </p>
        <p v-else>{{ __("&mdash;") }}</p>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,
        inheritAttrs: false,
    };
</script>
