<template>
    <filter-container :filter="filter">
        <template #filter>
            <date-time-input
                :name="currentField.name"
                :placeholder="placeholder"
                :options="currentField.pickerOptions"
                :value="value"
                v-bind="extraAttributes"
                @change="value = $event"
                @open="$emit('open')"
                @close="$emit('close')"
            />
        </template>
    </filter-container>
</template>

<script>
    import FilterField from "@nova/extendables/FilterField.vue";
    import omit from "lodash/omit";

    export default {
        extends: FilterField,
        emits: ["open", "close"],
        computed: {
            placeholder() {
                return (
                    this.currentField.placeholder || this.__("Select a date...")
                );
            },

            extraAttributes() {
                const attrs = omit(this.currentField.extraAttributes, [
                    "readonly",
                ]);

                return {
                    mode: this.currentField.mode || "range",
                    min: this.currentField.min,
                    max: this.currentField.max,
                    step: this.currentField.step,
                    ...attrs,
                };
            },

            debounceWait() {
                return 1;
            },
        },
    };
</script>
