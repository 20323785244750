<script>
    import DependentFormField from "@nova/extendables/DependentFormField.vue";
    import isNil from "lodash/isNil";
    import omitBy from "lodash/omitBy";

    export default {
        extends: DependentFormField,
        computed: {
            defaultAttributes() {
                return {
                    type: this.field.type || "text",
                    placeholder: this.field.placeholder,
                    class: this.errorClasses,
                    min: this.field.min,
                    max: this.field.max,
                    step: this.field.step,
                    pattern: this.field.pattern,

                    ...this.suggestionsAttributes,
                };
            },

            extraAttributes() {
                const attrs = this.field.extraAttributes;

                return {
                    ...this.defaultAttributes,
                    ...attrs,
                };
            },

            suggestionsId() {
                return `${this.field.attribute}-list`;
            },

            suggestions() {
                if (isNil(this.field.suggestions)) {
                    return [];
                }

                return this.field.suggestions;
            },

            suggestionsAttributes() {
                return {
                    ...omitBy(
                        {
                            list:
                                this.suggestions.length > 0
                                    ? this.suggestionsId
                                    : null,
                        },
                        isNil,
                    ),
                };
            },
        },
    };
</script>
