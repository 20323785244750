<template>
    <default-field
        :field="currentField"
        :errors="errors"
    >
        <template #field>
            <div class="flex items-stretch w-full relative">
                <span
                    class="flex items-center rounded-l border border-r-0 border-gray-300 dark:border-gray-700 px-3 whitespace-nowrap bg-gray-100 dark:bg-gray-800 text-gray-500 text-sm font-bold"
                >
                    {{ currentField.currency }}
                </span>

                <input
                    v-bind="extraAttributes"
                    class="form-control flex-1 rounded-l-none"
                    type="number"
                    @input="handleChange"
                    :value="value"
                    :id="currentField.uniqueKey"
                    :dusk="field.attribute"
                    :disabled="currentlyIsReadonly"
                />
            </div>
        </template>
    </default-field>
</template>

<script>
    import InputField from "./InputField.vue";

    export default {
        extends: InputField,
    };
</script>
