<template>
    <thead
        class="bg-blue-900 text-white dark:text-current border-t-0 border-l border-l-blue-900"
    >
        <tr>
            <!-- Select Checkbox -->
            <th
                v-if="shouldShowCheckboxes"
                class="w-[1%]"
                :class="{
                    'border-r border-gray-200 dark:border-gray-600':
                        shouldShowColumnBorders,
                }"
            >
                <span class="sr-only">{{ __("Selected Resources") }}</span>
            </th>

            <!-- Field Names -->
            <th
                v-for="(field, index) in fields"
                :key="field.uniqueKey"
                :class="{
                    [`text-${field.textAlign}`]: true,
                    'whitespace-nowrap': !field.wrapping,
                }"
                :style="{
                    width: field.indexWidth
                        ? parseInt(field.indexWidth) == field.indexWidth
                            ? `${field.indexWidth}px`
                            : field.indexWidth
                        : null,
                }"
            >
                <div
                    class="flex items-center gap-1"
                    :class="justifyClass(field)"
                >
                    <sortable-icon
                        v-if="sortable && field.sortable"
                        @sort="requestOrderByChange(field)"
                        @reset="resetOrderBy(field)"
                        :resource-name="resourceName"
                        :uri-key="field.sortableUriKey"
                    >
                        {{ field.indexName || field.name }}
                        <template v-if="field.sublabel">
                            ({{ field.sublabel }})
                        </template>
                    </sortable-icon>

                    <span v-else>
                        {{ field.indexName || field.name }}
                        <template v-if="field.sublabel">
                            ({{ field.sublabel }})
                        </template>
                    </span>

                    <help-text-tooltip
                        v-if="field.helpText"
                        :text="field.helpText"
                        class="-mb-px"
                    />
                </div>
            </th>

            <!-- View, Edit, and Delete -->
            <th
                v-if="showActions"
                class="text-center"
            >
                {{ __("Actions") }}
            </th>
        </tr>
    </thead>
</template>

<script>
    export default {
        emits: ["order", "reset-order-by"],

        props: {
            resourceName: String,
            shouldShowColumnBorders: Boolean,
            shouldShowCheckboxes: Boolean,
            fields: {
                type: [Object, Array],
            },
            sortable: Boolean,
            showActions: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            requestOrderByChange(field) {
                this.$emit("order", field);
            },

            resetOrderBy(field) {
                this.$emit("reset-order-by", field);
            },

            justifyClass(field) {
                if (field.textAlign === "center") {
                    return "justify-center";
                }

                if (field.textAlign === "right") {
                    return "justify-end";
                }

                return "justify-start";
            },
        },
    };
</script>
