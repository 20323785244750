<script>
    import DependentFormField from "./DependentFormField.vue";
    import debounce from "lodash/debounce";

    export default {
        extends: DependentFormField,

        data: () => ({
            search: "",
            selectedResource: null,
            selectedResourceId: null,
            availableResources: [],
            withTrashed: false,
        }),

        methods: {
            selectResource(resource) {
                this.selectedResource = resource;
                this.selectedResourceId = resource.value;

                if (this.field) {
                    if (typeof this["emitFieldValueChange"] == "function") {
                        this.emitFieldValueChange(
                            this.fieldAttribute,
                            this.selectedResourceId,
                        );
                    } else {
                        Nova.$emit(
                            this.fieldAttribute + "-change",
                            this.selectedResourceId,
                        );
                    }
                }
            },

            handleSearchCleared() {
                this.availableResources = [];
            },

            clearSelection() {
                this.selectedResource = null;
                this.selectedResourceId = null;
                this.availableResources = [];

                if (this.field) {
                    if (typeof this["emitFieldValueChange"] == "function") {
                        this.emitFieldValueChange(this.fieldAttribute, null);
                    } else {
                        Nova.$emit(this.fieldAttribute + "-change", null);
                    }
                }
            },

            performSearch(search) {
                this.search = search;

                const trimmedSearch = search.trim();

                // If the user performs an empty search, it will load all the results
                // so let's just set the availableResources to an empty array to avoid
                // loading a huge result set

                if (trimmedSearch == "") {
                    return;
                }

                this.searchDebouncer(() => {
                    this.getAvailableResources(trimmedSearch);
                }, 500);
            },

            searchDebouncer: debounce((callback) => callback(), 500),

            toggleWithTrashed() {
                this.withTrashed = !this.withTrashed;
            },

            enableWithTrashed() {
                this.withTrashed = true;
            },

            disableWithTrashed() {
                this.withTrashed = false;
            },
        },
    };
</script>
